jQuery(document).ready(function(){  
    jQuery('.btn-readmore-nr').dblclick(false);
    jQuery(".page-template-location-page .icon-search").click( function() {
      jQuery(".search-form").submit();
    });
    jQuery(".page-template-location-page #btn-search").click( function() {
      jQuery(".search-form").submit();
    });

    jQuery(".page-template-location-page #order-by-filter").change(function () {
        var crr = jQuery('#order-by-filter option:selected').val();
         window.location.href = crr;
    });

    jQuery(".page-template-location-page #all-states").change(function () {
        var crr = jQuery('#all-states option:selected').val();
         window.location.href = crr;
    });
 
    jQuery(".page-template-location-page #all-types").change(function () {
        var crr = jQuery('#all-types option:selected').val();
         window.location.href = crr;
    });

    jQuery(".page-template-location-page .icon-gps-indicator").click( function() {
      getLocation();
    }); 

    jQuery(".page-template-newsroom-page .icon-search, .page-template-newsroom-video-page .icon-search, .page-template-newsroom-media-page .icon-search").click( function() {
      jQuery(".nr-search-form").submit();
    });



    jQuery(".btn-readmore-nr").click( function() {
      var ajaxurl = jQuery('.crr_ajax_url').val();
      var crr_page_num = jQuery('.crr_page_num').val();
      var search_text = jQuery('.search_text').val();
      var tag_filter = jQuery('.tag_filter').val();
      var sus_id = $("li.active").find(".category-buttons").attr("sus-id");
      if (!sus_id) {
        sus_id = $(".sus-id-value").val();
      }
      var checkbutton = "true";

      if( jQuery('.page_type').length ){
         var page_type = jQuery('.page_type').val();
      }else{
         var page_type = '';
      }
      ajaxLoadmorepage(ajaxurl, crr_page_num, search_text, tag_filter, page_type, sus_id, checkbutton);
      var crr_page_num = jQuery(".crr_page_num").val();
      var new_page_num = Number(crr_page_num) + 1;
      jQuery(".crr_page_num").val(new_page_num);

    });

    jQuery('.desktop#service-state').on('change', function() {

      var crr_check = jQuery(this).find(":selected").val();
      var crr_att = jQuery(this).find(":selected").attr("data-value");
      if(crr_att == 0){
         jQuery(".no-state").hide();
        jQuery(".service-result").fadeIn();
        jQuery(".yes-state").fadeIn();

      }else{
        jQuery(".yes-state").hide();
        jQuery(".service-result").fadeIn();
        jQuery(".no-state").fadeIn();
      }
     /* var innerw = jQuery(window).innerWidth();
      if(innerw < 769){

      }*/
    });

    jQuery('.mobile#service-state').on('change', function() {

      var crr_check = jQuery(this).find(":selected").val();
      var crr_att = jQuery(this).find(":selected").attr("data-value");
      if(crr_att == 0){
        jQuery(".no-state").hide();
        jQuery(".service-result").fadeIn();
        jQuery(".yes-state").fadeIn();

      }else{
        jQuery(".yes-state").hide();
        jQuery(".service-result").fadeIn();
        jQuery(".no-state").fadeIn();
      }
     /* var innerw = jQuery(window).innerWidth();
      if(innerw < 769){

      }*/
    });


    //jQuery('.category-button').categoryFilter();


    jQuery(".category-buttons").click( function() {
      //reset element
      $(".resuil-div").html("");
      var valueset = 3;
      jQuery(".crr_page_num").val(valueset);

      var ajaxurl = jQuery('.crr_ajax_url').val();
      var crr_page_num = jQuery('.crr_page_num').val();
      var search_text = jQuery('.search_text').val();
      var tag_filter = jQuery('.tag_filter').val();

      if( jQuery('.page_type').length ){
         var page_type = jQuery('.page_type').val();
      }else{
         var page_type = '';
      }

      var sus_id = jQuery(this).attr("sus-id");

      ajaxLoadMoreValue(ajaxurl, crr_page_num, search_text, tag_filter, page_type, sus_id);


    });

   jQuery(".category-buttons-sfh-landing").click( function() {
      //reset element
      $(".resuil-div").html("");
      var valueset = 3;
      jQuery(".crr_page_num").val(valueset);

      var ajaxurl = jQuery('.crr_ajax_url').val();
      var crr_page_num = jQuery('.crr_page_num').val();
      var search_text = jQuery('.search_text').val();
      var tag_filter = jQuery('.tag_filter').val();

      if( jQuery('.page_type').length ){
         var page_type = jQuery('.page_type').val();
      }else{
         var page_type = '';
      }

      var sus_id = jQuery(this).attr("sus-id");

      ajaxLoadMoreValueSfhLanding(ajaxurl, crr_page_num, search_text, tag_filter, page_type, sus_id);


    });

    jQuery(".btn-readmore-resource-download").click( function(e) {
        e.preventDefault();
        getResourceRecords('.crr-download-page-num', 'download-group');
    });

    jQuery(".btn-readmore-resource-video").click( function(e) {
        e.preventDefault();
        getResourceRecords('.crr-video-page-num', 'video-group');
    });

    resetPageNumberRecyclingResources();
});

//Handle autocomplete for search field in location page
window.onload = function () {
  if(document.getElementById('map_search') !== null){
    const map1 = new google.maps.Map(document.getElementById('map_search'), {
      center : { lat: -42.8282067, lng: 147.2745492 },
      zoom : 15
    });
    
    const card = document.getElementById('location-search-container');
    let search_input = document.getElementById('search-input');
    if(search_input !== null){
      map1.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);

      const autocomplete = new google.maps.places.Autocomplete(search_input,{
        componentRestrictions: { country: "au" },
        fields: ["formatted_address", "geometry", "name", "address_components"],
        types: ["establishment","geocode"]     
      });
      google.maps.event.addListener(autocomplete, 'place_changed', function () {        
        const place = autocomplete.getPlace();
        if (!place.geometry) {        
          return;
        }
        const gg_formatted_address = place.formatted_address;
        const lastIndex = gg_formatted_address.lastIndexOf(',');        
        const new_address = (lastIndex < 0) ? gg_formatted_address : gg_formatted_address.substr(lastIndex+1);     
        if(new_address.match(/\d{4}/g)) {
          if(gg_formatted_address.indexOf(place.name) < 0){
            search_input.value = place.name + ', '+ new_address;
          }else {
            search_input.value = new_address;
          }         
        } else {
          getAddressFromGeocoder(place, search_input);
        }

        //commented out to avoid popping up of google container from showing again after done selecting
        // document.getElementById('search-input').focus();
        //task: https://cleanaway2.atlassian.net/browse/PCWY-222
        //desc: Once user has select the item from Google drop, page should automatically load to show results
        $('.search-form').submit();
      });
    }    
  }

  /*
  * Initial checking whether the input search has a value or not and the toggle the edit and delete icons 
  */
  if(window.location.href.indexOf("our-locations") > -1) { 
    if($('#search-input').val().length <=0) {
      $(".location-edit").addClass("hidden");
      $(this).focus();  
    } else {
      $(".location-text-delete").addClass("hidden");
      $('#search-input').prop('disabled', true);  
    }
  }


  var addressInputElement = $('.location-search__input');
  addressInputElement.on('focus', function () {
    var pacContainer = $('.pac-container');
    $(addressInputElement.parent()).append(pacContainer);
  })
}

//Adjust search text when select an autocomplete suggestion
function getAddressFromGeocoder(place, field){
  geocoder = new google.maps.Geocoder();
  const latlng = new google.maps.LatLng(
    place['geometry']['location'].lat(),
    place['geometry']['location'].lng()
  );
  let postcode_input = document.getElementById('search-postcode');
  geocoder.geocode({ 'latLng': latlng }, function (results) {
    const location = results.filter(function(address){          
      return address.types.includes('postal_code');
    });
    
    const addressComponent = location[0].address_components;    
    const postcode = Object.values(addressComponent).filter(function(component){
      return component.types.includes('postal_code');
    });
    let custom_address = [];
    Object.values(addressComponent).forEach(function(component){      
      if(component.types.includes('postal_code')){
        custom_address['postal_code'] = component.short_name;

      }
      if(component.types.includes('locality')){
        custom_address['locality'] = component.short_name;

      }
      if(component.types.includes('administrative_area_level_1')){
        custom_address['state'] = component.short_name;

      }
    });
    const gg_formatted_address = place.formatted_address;
    if(gg_formatted_address.indexOf(place.name) < 0) {
      field.value = place.name + ', ' + custom_address['locality'] + ' ' + custom_address['state'] + ' ' + custom_address['postal_code'];
    } else if(gg_formatted_address.indexOf(place.name) >= 0 || gg_formatted_address.indexOf(custom_address['state'])) {
      field.value = gg_formatted_address + ' ' + custom_address['postal_code'];
    } else {
      field.value = gg_formatted_address + ' ' + custom_address['state'] + ' ' + custom_address['postal_code'];
    }
  });
}

function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition, showErr);
  } else {
    return false;
  }
}

function showPosition(position) {
  jQuery('.page-template-location-page #current-lat').val(position.coords.latitude);
  jQuery('.page-template-location-page #current-lng').val(position.coords.longitude);

  jQuery("#search-input").val("");
  jQuery("#search-limit").val();
  
  jQuery(".search-form").submit();
}
function showErr(error){
  if(error.code == 1){
     alert("You have blocked detect location.");
  }
}

function ajaxLoadMoreValue(ajaxurl, crr_page_num, search_text, tag_filter, page_type, sus_id){
    var data = {
        action: 'more_newsroom',
        crr_page: crr_page_num,
        search_text: search_text,
        tag_filter: tag_filter,
        page_type: page_type,
        sus_id: sus_id
    };
    jQuery("#overlay-nr").fadeIn(300); 
    jQuery.post(ajaxurl, data, function(response) {
        var data = JSON.parse(response);
        var countData = data.length;
        var count = 0;
        jQuery(".result-full-div").html("");
        jQuery.each(data, function(i, items) {
            count +=1;
            if(count == 9){
              return false;
            }

            var title = items[1];
            var short_desc = items[2];
            var postdate = items[3];
            var readmore = items[4];
            var pic = items[5];
            var tag = items[6];
            var taglink = items[7];
            var readmorelink = items[8];

            var new_element = jQuery(".clone-new-element").clone().addClass(items[0]);
            new_element.removeClass("clone-new-element").addClass("newsroom__blog--blogbox").appendTo(".result-full-div");
            new_element.find(".title").text(title);
            new_element.find(".short-desc").html(short_desc);
            new_element.find(".readmore-link").attr("href", readmorelink);
            new_element.find(".detail-link").attr("href", readmore);
            new_element.find(".post-date").text(postdate);
            new_element.find(".pic").css("background-image","url('"+pic+"')");
            new_element.find(".tag").text(tag);
            new_element.find(".taginfo a").attr("href", taglink);
            new_element.addClass("newclass");

            
            new_element.fadeIn();


        });
        // var crr_page_num = jQuery(".crr_page_num").val();
        // var new_page_num = Number(crr_page_num) + 1;
        // jQuery(".crr_page_num").val(new_page_num);
         if(count == 9 ){
          jQuery(".btn-readmore").show();
        }else{
          jQuery(".btn-readmore").hide();
        }

        //End ajax
        setTimeout(function(){
          jQuery("#overlay-nr").fadeOut(300);
        },500);
    });
}

function ajaxLoadMoreValueSfhLanding(ajaxurl, crr_page_num, search_text, tag_filter, page_type, sus_id){
    var data = {
        action: 'more_newsroom',
        crr_page: crr_page_num,
        search_text: search_text,
        tag_filter: tag_filter,
        page_type: page_type,
        sus_id: sus_id
    };
    jQuery("#overlay-nr").fadeIn(300); 
    jQuery.post(ajaxurl, data, function(response) {

        var data = JSON.parse(response);
        var countData = data.length;
        var count = 0;
        jQuery(".result-full-div").html("");
        jQuery.each(data, function(i, items) {
            count +=1;
            if(count == 5){
              return false;
            }

            var title = items[1];
            var short_desc = items[2];
   
            var postdate = items[3];
            var readmore = items[4];
            var pic = items[5];
            var tag = items[6];
            var taglink = items[7];

            var new_element = jQuery(".clone-new-element").clone().addClass(items[0]);
            new_element.removeClass("clone-new-element").addClass("newsroom__blog--blogbox").appendTo(".result-full-div");
            new_element.find(".title").text(title);
            new_element.find(".short-desc").html(short_desc);
            new_element.find(".readmore-link").attr("href", readmore);
            new_element.find(".detail-link").attr("href", readmore);
            new_element.find(".post-date").text(postdate);
            new_element.find(".pic").css("background-image","url('"+pic+"')");
            new_element.find(".tag").text(tag);
            new_element.find(".taginfo a").attr("href", taglink);
            new_element.addClass("newclass");

            
            new_element.fadeIn();


        });
        //End ajax
        setTimeout(function(){
          jQuery("#overlay-nr").fadeOut(300);
        },500);
    });
}






function ajaxLoadmorepage(ajaxurl, crr_page_num, search_text, tag_filter, page_type, sus_id, checkbutton){
    var data = {
        action: 'more_newsroom',
        crr_page: crr_page_num,
        search_text: search_text,
        tag_filter: tag_filter,
        page_type: page_type,
        sus_id: sus_id,
        checkbutton: checkbutton
    };
    jQuery("#overlay-nr").fadeIn(300); 
    jQuery.post(ajaxurl, data, function(response) {
        var data = JSON.parse(response);
        var countdatareturn = data.length;
        var get_sus_id = $("li.active").find(".category-buttons").attr("sus-id");
        var count = 0;
        //var container = $('<div class="resuil-div tab'+get_sus_id+'" />');
        jQuery.each(data, function(i, items) {
            //console.log(data);
            /*
            jQuery.each(items, function(z, item) {
              console.log(item);
            });
            */
            count +=1;
            if(count == 5){
              return false;
            }

            var postid = items[0];
            var title = items[1];
            var short_desc = items[2];
            var postdate = items[3];
            var readmore ="";
            var pic = items[5];
            var tag = items[6];
            var taglink = items[7];
            var readmorelink = (typeof items[8] == 'boolean') ? '' : items[8];
            var btn_option = items[9];

            if (readmorelink) {
              readmore = readmorelink;
            }else{
              readmore = items[4];
            }
          
            var new_element = jQuery(".clone-new-element").clone().addClass(items[0]);
            new_element.removeClass("clone-new-element").addClass("newsroom__blog--blogbox");
            new_element.find(".title").text(title);
            new_element.find(".short-desc").html(short_desc);
            new_element.find(".post-date").text(postdate);
            new_element.find(".tag").text(tag);
            new_element.find(".pic").css("background-image","url('"+pic+"')");
            new_element.find(".taginfo a").attr("href", taglink);
            new_element.find(".detail-link").attr("href", readmore);
            // Check if readmore is not empty
            if(readmorelink !== '') {
              // Check if pdf or link
              new_element.find(".readmore-link").attr("href", readmorelink);
              if (btn_option == "pdf") {
                if (new_element.find(".readmore__media a").length > 0) {
                  new_element.find(".readmore__media a").text("Download PDF");
                } else {
                  new_element.find(".readmore-link span").text("Download PDF");
                }
              } else {
                if (new_element.find(".readmore__media a").length > 0) {
                  new_element.find(".readmore__media a").text("Read More");
                } else {
                  new_element.find(".readmore-link span").text("Read More"); 
                }
              }
            } else {
              // Attribute removal and text display for Flexi w/o Link/File
              new_element.find(".detail-link").removeAttr("href");
              if (new_element.find(".readmore__media a").length > 0) {
                new_element.find(".readmore__media a").text("No Document/Link Attached");
                new_element.find(".readmore__media a").removeAttr("href");
              } else {
                new_element.find(".readmore-link span").text("No Document/Link Attached");
                new_element.find(".readmore-link").removeAttr("href");
              }
            }
            new_element.find(".eight-section__info--tag a").attr("href", function(i, val){
              return val + taglink;
            });
            new_element.fadeIn();
            // var new_element = '<div class="filter all-cate col-lg-3 col-md-4 col-sm-6 col-xs-12 newsroom__blog--blogbox '+postid+' newclass">';
            // new_element += '<a class="detail-link" href="'+readmore+'"><span class="pic" style="background-image:url('+pic+');" ></span></a>';
            // new_element += '<div class="info"><div class="taginfo"><span class="date post-date">'+postdate+'</span><a href="?'+taglink+'"><span class="tag">'+tag+'</span></a></div>';
            // new_element += '<div id="fos" class="text"><a class="detail-link" href="'+readmore+'"><h4 class="title">'+title+'</h4></a>';
            // new_element += '<div class="textonly line-clamp line-clamp-3 short-desc"><p>'+short_desc+'</p></div>';
            // new_element += '<span class="readmore"><a href="'+readmore+'">Read More</a></span></div></div></div>';
            new_element.insertAfter(".newsroom__blog--blogbox:last");


        });
        //container.insertBefore(".loadmoreposition");
        if(count == 5){
          jQuery(".btn-readmore").show();
        }else{
          jQuery(".btn-readmore").hide();
        }

        //End ajax
        setTimeout(function(){
          jQuery("#overlay-nr").fadeOut(300);
        },500);
    });
}

//Call function search on contact-us Page.
function btnsearchOnContact() {
    var getInputLocal = $(".seachOnContactUs").val();
    var originurl   = $("#urlLocationPage").val();
    var search_limit = $("#search-limit").val();
    window.location.href = originurl + "?t=" + getInputLocal+"&limit="+search_limit+"&lng=&lat=";
    return false;
}

(function ( $ ) {
  $.fn.categoryFilter=function(selector){
    this.click( function() {
      var categoryValue = $(this).attr('data-filter');
      $(this).addClass('active').siblings().removeClass('active');
      if(categoryValue=="all") {
        $('.filter').show(1000);
      } else {
        $(".filter").not('.'+categoryValue).hide('3000');
              $('.filter').filter('.'+categoryValue).show('3000');
      }
    });
  }
}( jQuery ));


//remove search text our location page 
/**
* Task:   BAUM-548
* Author: Joseph Ricohermoso
* Date:   2021-06-15
* Desc:   We're using the same .location-remove_input for all clear search text result pages
*         but base on design decision this class should only be used under location search result page.
*         Let's create other selector classes that are more related to their pages. Put in array
*         for it's much readable that way.
*/
let removeBtns = [
                  ".location-remove_input",
                  ".generalsearch-remove_input",
                  ".medianewsroomsearch-remove_input",
                  ".newsroomsearch-remove_input",
                  ".videohubsearch-remove_input",
                  ".videonewsroomsearch-remove_input"
                  ];
removeBtns = removeBtns.join(",");
// End BAUM-548

jQuery(removeBtns).click( function() { 
    // var getInputLocal = $(".OutLocationURL").val() + "?&ty=service" ;
    var getInputLocal = $(".OutLocationURL").val();
    window.location.href = getInputLocal;
    return false;
});

getCurrentUrlLocation = window.location.href;
if (getCurrentUrlLocation.indexOf("?t") > 0 && $('.location-search__input, .inputSearchMedia, .videohub-search__input').val()) {
    $(removeBtns).removeClass("hidden");
}else{
   $(removeBtns).addClass("hidden");
}
        
$( ".inputSearchMedia, .location-search__input, .videohub-search__input" ).change(function() {
    if (getCurrentUrlLocation.indexOf("?t") > 0 && $(this).val() == "") {
        $(removeBtns).addClass("hidden");
        var getInputLocal = $(".OutLocationURL").val();
        window.location.href = getInputLocal;
        return false;
    }
});       


//custom main search newcleaway

var crr_page_taxo_back = $(".crr_page_taxo_back").val();
var crr_page_content_back = $(".crr_page_content_back").val();
var crr_page_content = $(".crr_page_content").val();
var crr_page_taxo = $(".crr_page_taxo").val();
var current_page = $(".crr_page").val();

$("#nav-next").one("click", function(e){

  current_page = parseInt(current_page);

  current_page = current_page + 1;

  var originurl   = $(".crr_page_url").val();

  window.location.href = originurl + "&crr_page=" + current_page;
});
$("#nav-previous").one("click", function(e){

  current_page = parseInt(current_page);

  current_page = current_page - 1;

  var originurl   = $(".crr_page_url").val();

  window.location.href = originurl + "&crr_page=" + current_page;
});

$("#nav-next-taxo").one("click", function(e){

  current_page = parseInt(current_page);

  current_page = current_page + 1;

  crr_page_taxo = parseInt(crr_page_taxo);

  var originurl   = $(".crr_page_url").val();

  window.location.href = originurl + "&crr_page_taxo=" + crr_page_taxo + "&crr_page=" + current_page;
});

$("#nav-previous-taxo").one("click", function(e){

  current_page = parseInt(current_page);

  current_page = current_page - 1;

  crr_page_taxo = parseInt(crr_page_taxo_back);

//crr_page_taxo = crr_page_taxo - 1;

var originurl   = $(".crr_page_url").val();

if (crr_page_taxo) {
  window.location.href = originurl + "&crr_page_taxo=" + crr_page_taxo + "&crr_page=" + current_page;
}else{
  window.location.href = originurl + "&crr_page=" + current_page;
}


});

$("#nav-next-content").one("click", function(e){

  current_page = parseInt(current_page);

  current_page = current_page + 1;

  crr_page_taxo = parseInt(crr_page_taxo);

//crr_page_taxo = crr_page_taxo + 1;

crr_page_content = parseInt(crr_page_content);

var originurl   = $(".crr_page_url").val();

window.location.href = originurl + "&crr_page_taxo=" + crr_page_taxo + "&crr_page=" + current_page + "&crr_page_content=" + crr_page_content;
});

$("#nav-previous-content").one("click", function(e){

  current_page = parseInt(current_page);

  current_page = current_page - 1;

  crr_page_taxo = parseInt(crr_page_taxo_back);

//crr_page_taxo = crr_page_taxo - 1;

crr_page_content = parseInt(crr_page_content_back);

//crr_page_content = crr_page_content - 1;

var originurl   = $(".crr_page_url").val();

if (crr_page_content) {
  window.location.href = originurl + "&crr_page_taxo=" + crr_page_taxo + "&crr_page=" + current_page + "&crr_page_content=" + crr_page_content;
}else{
  if (crr_page_taxo) {
    window.location.href = originurl + "&crr_page_taxo=" + crr_page_taxo + "&crr_page=" + current_page;
  }else{
    window.location.href = originurl + "&crr_page=" + current_page;
  }

}

});


//video hub
jQuery(".category-button-video").click( function() {

    var link_current = $("#current_page").val();

    window.history.pushState({}, document.title, link_current);


  //reset element
  //$(".resuil-div").html("");
  var valueset = 3;
  jQuery(".crr_page_num").val(valueset);

  var ajaxurl = jQuery('.crr_ajax_url').val();
  var crr_page_num = jQuery('.crr_page_num').val();
  var sus_id = jQuery(this).attr("sus-id");

  ajaxLoadMoreValueVideo(ajaxurl, crr_page_num, sus_id);

    jQuery('.search_text').val("");
    jQuery('.search-box').val("");
    jQuery('.tag_filter').val("");
    jQuery('.videohubsearch-remove_input').addClass('hidden');

});


function ajaxLoadMoreValueVideo(ajaxurl, crr_page_num, sus_id){
    var data = {
        action: 'more_video',
        crr_page: crr_page_num,
        sus_id: sus_id
    };

    jQuery.post(ajaxurl, data, function(response) {
        var data = JSON.parse(response);
        var countData = data.length;

        var count = 0;
        jQuery(".result-full-div").html("");
        jQuery.each(data, function(i, items) {

            count +=1;
            if(count == 9){
              return false;
            }

            var title = items[1];
            var short_desc = items[2];
            var postdate = items[3];
            var readmore = items[4];
            var pic = items[5];
            var tag = items[6];
            var taglink = items[7];
            var video_time = items[8];
            var link_current = $("#current_page").val();

            var new_element = jQuery(".clone-new-element").clone();
            new_element.attr("id", items[0]);
            new_element.removeClass("clone-new-element").addClass("newsroom__blog--blogbox videoblog--box videohub__blog--blogbox").appendTo(".result-full-div");
            new_element.find(".title").text(title);
            new_element.find(".description").html(short_desc);
            if (video_time) {
                new_element.find(".video_time").html(video_time);
            }else{
                new_element.find(".video_time").addClass("hidden");
            }
            
            //new_element.find(".detail-link").attr("href", readmore);
            new_element.find(".date").text(postdate);
            new_element.find(".pic").css("background-image","url('"+pic+"')");
            if (!tag) {
                new_element.find(".tag").addClass('hidden');
            }else{
                new_element.find(".tag").text(tag);
                new_element.find(".taglink_video").attr('href',link_current+"?"+taglink);
                
            }
            
            new_element.addClass("newclass");
            new_element.fadeIn();


        });

        if(count == 9 ){
          jQuery(".btn-readmore").removeClass("hidden");
        }else{
          jQuery(".btn-readmore").addClass("hidden");
        }
    });
}


jQuery(".btn-readmore-video-hub").click( function() {

    var ajaxurl = jQuery('.crr_ajax_url').val();

    var crr_page_num = jQuery('.crr_page_num').val();

    var tag_filter = jQuery('.tag_filter').val();

    if (tag_filter) {
        var sus_id = $("#tgname").val();
    }else{
        var sus_id = $("li.active").find(".category-button-video").attr("sus-id");
    }

    var search_text = jQuery('.search_text').val();

    var checkbutton = "true";

    ajaxLoadMoreVideoOnPage(tag_filter, search_text, ajaxurl, crr_page_num, sus_id, checkbutton);
    var crr_page_num = jQuery(".crr_page_num").val();
    var new_page_num = Number(crr_page_num) + 1;
    jQuery(".crr_page_num").val(new_page_num);

});

var getResourceRecordsLoading = false;

function getResourceRecords(pageNumClass, group_type) {
    // Make sure that's there's only one ongoing request
    if (getResourceRecordsLoading) {
      return;
    }
    let pageNumI = Number(jQuery(pageNumClass).val());
    ajaxLoadMoreDownloadOrVideo(pageNumI, group_type);
    jQuery(pageNumClass).val(++pageNumI);
}

function resetPageNumberRecyclingResources() {
    $('.crr-download-page-num').val(2);
    $('.crr-video-page-num').val(1);
}

function ajaxLoadMoreDownloadOrVideo(crr_page_num, group_type) {
    const ajaxurl = jQuery('.crr_ajax_url').val();
    const data = {
        action: 'more_resource',
        crr_page: crr_page_num,
        group_type,
        order_by: (group_type === 'video-group') ? $('.resource_video_ordering_field').val() : $('.resource_download_ordering_field').val()
    };

    // jQuery(".btn-readmore-resource-download").prop("disabled", true);
    getResourceRecordsLoading = true;
    jQuery.post(ajaxurl, data, function(response) {
        const data = JSON.parse(response);
        jQuery.each(data, function(i, items) {
            const title = items[1];
            const short_desc = items[2];
            const postdate = items[3];
            const pic = items[5];
            const tag = items[6];
            const taglink = items[7];
            const video_time = items[8];
            const filePath = items[10]
            const link_current = $("#current_page").val();

            var new_element = null;

            if (group_type === 'download-group') {
                new_element = jQuery(".clone-download-item-row").clone();
                new_element.removeClass("clone-download-item-row");
                new_element.find(".card-details__title").text(title);
                new_element.find("img").attr('src', pic);
                new_element.find('.card-details').click(function() {
                    window.open(filePath, '_blank').focus();
                });

                new_element.fadeIn();
                new_element.insertAfter(".download-item-row:last");
                new_element.addClass('download-item-row');
            } else {
                new_element = jQuery(".clone-video-item").clone();
                new_element.removeClass("clone-video-item");
                new_element.find(".video-item__title").text(title);
                new_element.find(".video-item__desc").text(short_desc);
                new_element.find('iframe').attr('src', filePath);

                new_element.fadeIn();
                new_element.insertAfter(".video-item:last");
                new_element.addClass('video-item');
            }
        });

        if (group_type === 'video-group') {
            setTimeout(function() {
                jQuery('.video-item:last').get(0).scrollIntoView();
            }, 1500);
        } 

        if (data.length < 4) {
            // If previous resultset is already less than 4
            // Then, it might be the last page
            if (group_type === 'download-group') {
                jQuery(".btn-readmore-resource-download").addClass("hidden");
            } else {
                jQuery(".btn-readmore-resource-video").addClass("hidden");
            }
        }

        getResourceRecordsLoading = false;
    });
}


function ajaxLoadMoreVideoOnPage(tag_filter, search_text, ajaxurl, crr_page_num, sus_id, checkbutton){
    var data = {
        action: 'more_video',
        tag_filter: tag_filter,
        search_text: search_text,
        crr_page: crr_page_num,
        sus_id: sus_id,
        checkbutton: checkbutton
    };

    jQuery.post(ajaxurl, data, function(response) {
        var data = JSON.parse(response);
        var count = 0;

        jQuery.each(data, function(i, items) {
            count +=1;
            if(count == 5){
              return false;
            }
            var title = items[1];
            var short_desc = items[2];
            var postdate = items[3];
            var readmore = items[4];
            var pic = items[5];
            var tag = items[6];
            var taglink = items[7];
            var video_time = items[8];
            var link_current = $("#current_page").val();

            var new_element = jQuery(".clone-new-element").clone();
            new_element.removeClass("clone-new-element").addClass("newsroom__blog--blogbox videoblog--box videohub__blog--blogbox");
            new_element.attr("id", items[0]);
            new_element.find(".title").text(title);
            new_element.find(".description").html(short_desc);
            if (video_time) {
                new_element.find(".video_time").html(video_time);
            }else{
                new_element.find(".video_time").addClass("hidden");
            }
            //new_element.find(".readmore-link").attr("href", readmore);
            //new_element.find(".detail-link").attr("href", readmore);
            new_element.find(".date").text(postdate);
            new_element.find(".pic").css("background-image","url('"+pic+"')");
            if (!tag) {
                new_element.find(".tag").addClass('hidden');
            }else{
                new_element.find(".tag").text(tag);
                new_element.find(".taglink_video").attr('href',link_current+"?"+taglink);
            }
            new_element.fadeIn();
            new_element.insertAfter(".videoblog--box:last");

        });
  
        if(count == 5 ){
          jQuery(".btn-readmore").removeClass("hidden");
        }else{
          jQuery(".btn-readmore").addClass("hidden");
        }
    });
}

$(document).on('click', ".btn-read-video", function () {

    var ajaxurl = jQuery('.crr_ajax_url').val();

    var box_id = $(this).parent().parent().parent().attr("id");

    if (!box_id) {
        box_id = $(this).parent().attr("id");
    }

    ajaxLoadOneVideo(box_id, ajaxurl);

});

$(document).on('click', ".modal-prev-video", function () {

    var ajaxurl = jQuery('.crr_ajax_url').val();

    var get_video_id = $("#modal-videohub").attr("data-box");

    var get_video_box = $("#" + get_video_id);

    var get_video_prev = get_video_box.prev();

    var get_video_prev_id = get_video_prev.attr("id");

    ajaxLoadOneVideo(get_video_prev_id, ajaxurl);

});


$(document).on('click', ".modal-next-video", function () {

    var ajaxurl = jQuery('.crr_ajax_url').val();

    var get_video_id = $("#modal-videohub").attr("data-box");

    var get_video_box = $("#" + get_video_id);

    var get_video_prev = get_video_box.next();

    var get_video_prev_id = get_video_prev.attr("id");

    ajaxLoadOneVideo(get_video_prev_id, ajaxurl);

});


function ajaxLoadOneVideo(box_id, ajaxurl){
    var data = {
        action: 'one_video',
        box_id: box_id,
    };

    jQuery.post(ajaxurl, data, function(response) {
    var data = JSON.parse(response);

    var get_video_box = $("#" + box_id);
    var get_video_next = get_video_box.next();
    var get_video_prev = get_video_box.prev();
    var get_button_video = data[4];
    var get_video_link = data[3];
    var tag = data[5];
    
    if (get_video_link) {
        var embed_video_link = get_video_link.replace("watch?v=", "embed/");
        var video_youtube_id = cwv_getParameterByName("v",get_video_link);
    }else{
        var embed_video_link = null;
        var video_youtube_id = null;
    }
    

    var sParameter = encodeURIComponent(data[1].trim());

    var get_name_site = $("#name_site").val().replace(/\s/g,'-');
    var link_current = $("#current_page").val();

    if (data[6] != false && data[7] != false) {
        get_video_tag = '<a target="_blank" href="'+link_current+'?tg='+data[6]['slug']+'">'+data[6]['name']+'</a>, <a target="_blank" href="'+link_current+'?tg='+data[7]['slug']+'">'+data[7]['name']+'</a>';
    }else if(data[6] != false && data[7] == false){
        get_video_tag = '<a target="_blank" href="'+link_current+'?tg='+data[6]['slug']+'">'+data[6]['name']+'</a>';
    }else if(data[6] == false && data[7] != false){
        get_video_tag = '<a target="_blank" href="'+link_current+'?tg='+data[7]['slug']+'">'+data[7]['name']+'</a>';
    }else{
        get_video_tag = null;
    }

    var share_linkedin = 'https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D'+video_youtube_id+'%26feature%3Dshare';
    var share_facebook = 'https://www.facebook.com/sharer/sharer.php?u='+get_video_link;
    var share_twitter = 'https://twitter.com/intent/tweet?url=https%3A//youtu.be/'+video_youtube_id+'&text='+sParameter;

    var videoModal = $("#modal-videohub");

    if (get_video_prev.length == 0) {
        videoModal.find(".modal-prev-video").addClass('hidden');
    }else{
        videoModal.find(".modal-prev-video").removeClass('hidden');
    }

    if (get_video_next.length == 0) {
        videoModal.find(".modal-next-video").addClass('hidden');
    }else{
        videoModal.find(".modal-next-video").removeClass('hidden');
    }

    videoModal.attr("data-box",data[0]);
    videoModal.find(".modal-title-video").text(data[1]);
    videoModal.find(".modal-description-video").html(data[2]);
    videoModal.find(".modal-video-url").attr("src",embed_video_link);

    if (!tag) {
        videoModal.find(".tag").addClass('hidden');
    }else{
        videoModal.find(".tag").text(tag);
        videoModal.find(".taglink_video").attr('href',link_current+"?"+data[9]);
        
    }

    if (get_video_tag) {
        videoModal.find(".list-video-tag").removeClass('invisible');
        videoModal.find(".modal-video-tag").html(get_video_tag);
    }else{
        videoModal.find(".list-video-tag").addClass('invisible');
    }

    videoModal.find(".modal-linked-video").attr("href",share_linkedin);
    videoModal.find(".modal-fb-video").attr("href",share_facebook);
    videoModal.find(".modal-twitter-video").attr("href",share_twitter);

    if (get_button_video) {
        var video_button = "";
        jQuery.each(get_button_video, function(i, items) {
            //video_items = Object.values(items);
            //video_items = Object.keys(items);

            var video_items = Object.keys(items).map(function(e) {
              return items[e];
            });
            
            if (video_items[0]['target']) {
                video_button += '<a class="button--green" target="_blank" href="'+video_items[0]['url']+'">'+video_items[0]['title']+'</a>';
            }else{
                video_button += '<a class="button--green" href="'+video_items[0]['url']+'">'+video_items[0]['title']+'</a>';
            }


        });
        $(".modal-button-link").html(video_button);
    }else{
        $(".modal-button-link").html("");
    }
    
    $('#modal-videohub').modal('show');
    });
}


function cwv_getParameterByName( name,href )
{
  name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
  var regexS = "[\\?&]"+name+"=([^&#]*)";
  var regex = new RegExp( regexS );
  var results = regex.exec( href );
  if( results == null )
    return "";
  else
    return decodeURIComponent(results[1].replace(/\+/g, " "));
}


$(document).ajaxStart(function () {
        $('.loading-ajx').removeClass('hidden');
    }).ajaxStop(function () {
        $('.loading-ajx').addClass('hidden');
});

$(".mobile-filter .category-button-video").click( function() {  
    $(".category-button-video").removeClass("active");
    $(this).addClass("active");

    var get_tab_text = $(this).text() + '<b class="caret"></b>';
    $(".dropbtn").html(get_tab_text);

    //set value for desktop menu 
    var get_sus_id = $(this).attr("sus-id");
    $(".desktop-filter:not(.desktop-filter-resources) li").removeClass("active");
    $('.desktop-filter .category-button-video[sus-id="'+get_sus_id+'"]').parent().addClass('active');

});

jQuery(".videohub .icon-video-search").click( function() {
  jQuery(".video-search-form").submit();
});

// if ($('.videohub').length > 0) {
//     $(document).ready(function(){
//         $(".drop-menu").click(function(){
//              $('.dropdown-content').toggle('slow');    
//         });
//     }); 
// }

//end video


//search custom

jQuery("#all-pages").change( function() {

    $(".menu-media-types").addClass("hidden");

    var get_option_selected = $("#all-pages option:selected").text();

    $(".page-types .dropdown-item").removeClass("selected");

    $(this).addClass("selected");
    
      var crr = jQuery(this).val();

      window.location.href = crr;
});



jQuery("#media-types").change( function() {

    $(".media-types .dropdown-item").removeClass("selected");

    $(this).addClass("selected");

    var crr = jQuery(this).val();

    if (crr !== "Choose an option") {
       window.location.href = crr;
    }

});

jQuery(".location-search .icon-search").click( function() {
    jQuery(".search-input").submit();
});


/*
 * Disabled enter function in keyboard for location input search
*/
$('.location-search__input').keypress(function (e) {
 var key = e.which;
 if(key == 13)  // the enter key code
  {
    return false;  
  }
});   


/*
 * Edit and Delete event functions for location input search
*/
$(".location-text-delete").click(() => {
    $('.location-search__input').val("");
});

$(".location-edit").click(function() {
    $('.location-search__input').prop('disabled', false);
    jQuery(".location-text-delete").removeClass("hidden");
    $(this).addClass("hidden");
    document.getElementById('search-input').focus();  
});