jQuery(document).ready(function($) {

    
    // Popup form fields interaction
    // To prevent pushing when the dropdown/radio field is hidden by conditional logic reset its value.
    // Make sure the field is focused before it's changed ( field has been displaying in UI ).
    var focused = false;
    var focusedId;
    $(document).on('focus','select, input[type=radio]',function(e){
        focused = true;
        focusedId = $(this).attr('id');
    });
    $(document).on('change','select, input[type=radio]',function(e){
        var formEle = $(this).parents('form');
        if(formEle.hasClass('talktousform') 
            || formEle.hasClass('bookchangeaserviceform')
            || formEle.hasClass('requestaquoteform')
            || formEle.hasClass('generalenquiriesform')
            || formEle.hasClass('decontaminationform')
            ) {
            if(focused == true && focusedId == $(this).attr('id')){
                var fieldValue = $(this).val();
                var fieldLabel = $(this).parents('li.gfield').find('label.gfield_label').text();
                formTitleDataLayer = $(this).parents('.gform_wrapper').find('.gform_title').text();
                dataLayer.push({
                    "event":"custom.form.field",
                    "form":formTitleDataLayer,
                    "action":"field complete",
                    "field": fieldLabel,
                    "value": fieldValue
                });
                focused = false;
            }
            
        }
    });

    //push dataLayer when click on search result for gtm
    dataLayer = window.dataLayer || []; 
    $(document).on('click','.search-result',function(e){
        var resultTitle = $(this).attr("title");
        var resultIndex = parseInt($(this).attr('data-index')) ;
        var searchTerm = $('#search-input').val();
        var totalSearchResults = parseInt($('#gtm-total-search-results').text());
        dataLayer.push({
            "event":"custom.search.start",
            "searchterm":searchTerm,
            "numResults":totalSearchResults,
            "result":resultTitle,
            "position":resultIndex
        });
    });
    $(document).bind('gform_post_render', function(e) {
        if($(".gform_footer").find("input[type='submit']").length > 0) {
            $(this).find("input[type='submit']").click(function() {
                var tracker = ga.getAll()[0];
                $(".googlecid_id").find('input:text').val(tracker.get('clientId'));
                $(".googleuid_id").find('input:text').val(tracker.get('userId'));
                $(".googletrackingid_id").find('input:text').val(tracker.get('trackingId'));
            });
        }
    });

    //Push dataLayer when complete submission and redirect to thankyou page
    //Include pages: thank-you, thank-you-page , recycling-hub-thank-you
    //Get value from footer.php, footer-ppc-thankyou.php
    var body_class = $('body').attr('class');
    if( body_class.indexOf('thankyou-page') > -1){
        var myFormTitle = $('.gtm-form-title').val();
        var myEmailHashed = $('.gtm-hashed-email').val();
        var myIndustry = $('.gtm-form-industry').val();
        var myServices = $('.gtm-form-services').val();
        var myEmail = $('.gtm-form-email').val();
        var myFirstName =  $('.gtm-form-first-name').val();
        var myLastName =  $('.gtm-form-last-name').val();
        var myPhone =  $('.gtm-form-phone').val();
        
        if(myFormTitle != "" && myEmailHashed != ""){
            var dataLayerAttributes = {
                "event" : "custom.form.complete",
                "form" : myFormTitle,
                "action" : "complete",
                "hashedEmail" : myEmailHashed,
                "email": myEmail,
            };

            //push optional value if it not empty
            if(myIndustry != ''){
                dataLayerAttributes.industry = myIndustry;
            }
            if(myServices != ''){
                dataLayerAttributes.services = myServices;
            }
            if(myPhone != ''){
                dataLayerAttributes.phone = myPhone;
            }
            if(myFirstName != ''){
                dataLayerAttributes.first_name = myFirstName;
            }
            if(myLastName != ''){
                dataLayerAttributes.last_name = myLastName;
            }

            dataLayer.push(dataLayerAttributes);
           
        }
    };

})