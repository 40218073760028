jQuery(document).ready(function($) {


    jQuery( document ).bind( 'gform_post_render', function() {
        var gfRecaptchaPoller = setInterval( function() {
        if( ! window.grecaptcha || ! window.grecaptcha.render ) {
        return;
        }
        renderRecaptcha();
        clearInterval( gfRecaptchaPoller );
        }, 0 );
    } ); 
     //validation datapicker
    if (!$("body").hasClass('post-type-archive-tribe_events') && !$("form").hasClass('wasteBrokersRequest')  && !$("form").hasClass('christmasNewYearService') ) {
        if($(".datepicker").length > 0) {
            jQuery(".datepicker").datepicker('destroy');
            $(".datepicker").each(function(){
                if($(this).attr('placeholder') != ''){
                    $(this).attr('placeholder','dd/mm/yyyy');
                }
            });
            $('body').find(".datepicker").datepicker({ minDate: 0,dateFormat: 'dd/mm/yy' });


            //BAUM-4633 new behavior for datepicker
            $('.datepicker').datepicker({
                autoclose: true,
                todayHighlight: true,
                orientation: "auto"
            });

            
        }
    }    
    

    gform.addAction( 'gform_input_change', function( elem, formId, fieldId ) {
        if($('form.bookchangeaserviceform').attr('id') === 'gform_'+formId){
            if($('#field_'+formId+'_'+fieldId).hasClass('select_waste_stream')
                || $('#field_'+formId+'_'+fieldId).hasClass('number_of_bins')
                || $('#field_'+formId+'_'+fieldId).hasClass('collection_date')){
                addedServices = getBookingServiceAddedFieldsValue(blockAdded);
                addAddedServiceDataToGravityFields(blockAdded);
            }
        }
    }, 10 );

    $('body').on("click",".add-service1",function(e){
        e.preventDefault();
        service = parseInt($(this).attr('data-services'), 10);
        if(service < 9) {
            service += 1;
            $(this).attr('data-services', service);
            var block = ".block" + service;
            $(block +' .validation_message').remove();
            $(block).removeClass('gfield_visibility_hidden');
            $(block).removeClass('gfield_error');
            $(block).find('.datepicker,.gfield_select,input').removeAttr('disabled');
            $(block).addClass('gfield_visibility_visible');
            $('.gform_body .gform_hidden[value=false]').eq(0).val('true').parents('.gform_hidden').addClass('block'+service);
            blockAdded.push(block);
            addedServices = getBookingServiceAddedFieldsValue(blockAdded);
            addAddedServiceDataToGravityFields(blockAdded);
            $('.totalAddedService').find('input[type=number]').val(blockAdded.length);
            $(block).trigger('click');
            $('.gf_request_type input[value="Book a Service"]').trigger('click');
        }

    });
    //DUPLICATE booking form///////////////////////////////////////////////
    //f($('.add-service1').length > 0 && $('.add-cancellation').length > 0){
        var service = 0;
            leftFields1 = [],
            middleFields1 = [],
            rightFields1 = [],
            blockAdded = [];
            addedServices = [];

        // Load field classes.
        $('.gf_left_third1').each(function(i, v) {
            leftFields1.push($(this));
        });
        $('.gf_middle_third1').each(function(i, v) {
            middleFields1.push($(this));
        });
        $('.gf_right_third1').each(function(i, v) {
            rightFields1.push($(this));
        });

        var SwapFields = function() {
            $.each(leftFields1, function(i, v) {
                leftFields1[i].removeClass('gf_left_third1').addClass('gf_left_half1');
                if (leftFields1[i].css('display') == 'block') {
                    leftFields1[i].css('display', 'inline-block');
                }
            });
            $.each(middleFields1, function(i, v) {
                middleFields1[i].removeClass('gf_middle_third1').addClass('gf_right_half1');
                if (middleFields1[i].css('display') == 'block') {
                    middleFields1[i].css('display', 'inline-block');
                }
            });
            $.each(rightFields1, function(i, v) {
                rightFields1[i].removeClass('gf_right_third1').addClass('gf_left_half1');
                if (rightFields1[i].css('display') == 'block') {
                    rightFields1[i].css('display', 'inline-block');
                }
            });
        };

        var RestoreFields = function() {
            $.each(leftFields1, function(i, v) {
                leftFields1[i].removeClass('gf_left_half1').addClass('gf_left_third1');
            });
            $.each(middleFields1, function(i, v) {
                middleFields1[i].removeClass('gf_right_half1').addClass('gf_middle_third1');
            });
            $.each(rightFields1, function(i, v) {
                rightFields1[i].removeClass('gf_left_half1').addClass('gf_right_third1');
            });
        };


        if($('.gf_request_type input[type=radio]:checked').val() == 'Cancel a Scheduled Service'){

            $(".gf_left_half1").css("width", "50%!important;");

            SwapFields();
        } else {
            RestoreFields();
        }

        $('.gf_request_type input[type=radio]').change(function() {
            if ($(this).val() == 'Cancel a Scheduled Service') {
                SwapFields();
            } else {
                RestoreFields();
            }
        });

        $('body').find('.gf_request_type input[type=radio]').on('click', function(e){
            if ($(this).val() == 'Cancel a Scheduled Service') {
                SwapFields();
            } else {
                RestoreFields();
            }
        });


        //Update data services if there is a validation error
        $(document).bind('gform_post_render', function(e) {
            e.preventDefault();
            var gfRecaptchaPoller = setInterval( function() {
                if( ! window.grecaptcha || ! window.grecaptcha.render ) {
                    return;
                }
                renderRecaptcha();
                clearInterval( gfRecaptchaPoller );
            }, 0 );



            if($('.gf_request_type input[value="Change a Service"]').is(':checked')){
                $('body').find('.select_waste_stream, .number_of_bins, .collection_date').not('.wastestream1 ').addClass("displaynonefieldform");
            }else{
                $('body').find('.select_waste_stream, .number_of_bins, .collection_date').removeClass("displaynonefieldform");
                $('body').find('.collection_date .datepicker,.number_of_bins input ,.select_waste_stream .gfield_select').removeAttr('disabled');
            }
            if ($('.modal-book-a-service').find('.gform_validation_error').length > 0 && $(".bookchangeaserviceform").length > 0) {
                if($('.block1').find('.gfield_select').val() == 'Please select'){
                    $(".select_waste_stream").filter('.block1').addClass('gfield_error');
                }
                $('.add-btn[data-active="true"]').attr('data-services', blockAdded.length+1);
                for (var i = 0; i < blockAdded.length; i++) {
                    $(blockAdded[i]).removeClass('gfield_visibility_hidden');
                    $(blockAdded[i]).addClass('gfield_visibility_visible');
                    $(blockAdded[i]).find('.validation_message').remove();
                    addDataToGravityFieldByIndex(i);
                }
                var emptyRowDataItems = addedServices.filter(service => service.waname === '' || service.waname === 'Please select' || service.date === '');
                emptyRowDataItems.forEach(row => {
                    if($(row.blockClass).find(".datepicker").val().length === 0){
                        $(".calendar").filter($(row.blockClass)).addClass('gfield_error');
                    }
                    if($($(row.blockClass)).find('.gfield_select').val() == 'Please select' || $($(row.blockClass)).find('.gfield_select').val() == ''){
                        $(".select_waste_stream").filter($(row.blockClass)).addClass('gfield_error');  
                    }
                });
                for(var i= blockAdded.length+2; i< 10; i++){
                    resetRowService('.block'+i);
                }
                
                //recall validation date
            }
            
            $( ".btn-blue" ).parent().css({ "text-align" : "center", "margin" : "30px 0px", "padding-top" : "20px" });

            //validation datapicker after submition
            if($(".datepicker").length > 0) {
                jQuery(".datepicker").datepicker('destroy');
                $('body').find(".datepicker").datepicker({ 
                    minDate: 0,
                    dateFormat: 'dd/mm/yy',
                    beforeShow: function(input,inst){
                        inst.dpDiv.css({ 
                           'top': input.offsetHeight+ 'px', 
                           'left':(input.offsetWidth - input.width)+ 'px'
                        });
                    }
                });
                var today = new Date();
                today.setDate(today.getDate()+1);
                $('body').find(".christmasNewYearService .to_date .datepicker").datepicker({ 
                    minDate: today,
                    dateFormat: 'dd/mm/yy'
                });
                $(".datepicker").each(function(){
                    if($(this).attr('placeholder') != ''){
                        $(this).attr('placeholder','dd/mm/yyyy');
                    }
                });
                
            }

            if($(".embed_url_book_service").length > 0) {
            
                var currentURL = location.protocol + '//' + location.host + location.pathname;
                $('body').find(".embed_url_book_service").find('input:text').val(currentURL);
            }

            if($(".embed_url_quote").length > 0) {
                $('body').find(".embed_url_quote").find('input:text').val(window.location.href);
            }
            //$('.add-service1').trigger('click');

            // add a new service
            // $('body').on("click",".add-service1",function(e){
            //     e.preventDefault();
            //     //service = parseInt($(this).attr('data-services'), 10);
            //     if(service < 9) {
            //         service += 1;
            //         $(this).attr('data-services', service);
            //         var block = ".block" + service;
            //         $(block).removeClass('gfield_visibility_hidden');
            //         blockAdded.push(block);

            //         $(block).trigger('click');
            //         $('.gf_request_type input[value="Book a Service"]').trigger('click');
            //         //console.log(blockAdded);
            //     }

            // });
            $('body').find('.remove-service').on('click', function(e){
                e.preventDefault();
                blockIndex = getCurrentBlockIndex($(this).attr('data-div'));
                var getCurrentBlock = "." + $(this).attr('data-div');
                $(getCurrentBlock).addClass('gfield_visibility_hidden');
                
                blockAdded = jQuery.grep(blockAdded, function(value) {
                  return value != getCurrentBlock;
                });                
                //remove a service or cancellation
                resetRowService(getCurrentBlock);
                
                var cloneCurrentBlock  = $(getCurrentBlock).clone(true);
                resetRowService(cloneCurrentBlock);
                $(cloneCurrentBlock).find('.datepicker').removeClass('hasDatepicker').removeData('datepicker')
                .unbind()
                .datepicker({
                    dateFormat: "dd/mm/yy",
                    minDate: 0
                });
                //move the block removed to the end of list and re-order blocks by changing css class
                $(getCurrentBlock).remove();
                cloneCurrentBlock.insertBefore($('.add-btn[data-active="true"]').parent());
                $('.block'+blockIndex).addClass('block10').removeClass('block'+blockIndex);
                renameBlockClass(blockIndex);
                blockAdded = reindexBlockAdded(blockAdded);
                addedServices = getBookingServiceAddedFieldsValue(blockAdded);
                addAddedServiceDataToGravityFields(blockAdded);
                $('.totalAddedService').find('input[type=number]').val(blockAdded.length);
                if($('.add-btn[data-active="true"]').attr('data-services') >= 0) {

                    service = parseInt($('.add-btn[data-active="true"]').attr('data-services'), 10);                        
                    service -= 1;

                    $('.add-btn[data-active="true"]').attr('data-services', service);
                    
                    if($('.add-btn[data-active="true"]').hasClass('add-service1')){
                        $('.gf_request_type input[value="Book a Service"]').trigger('click');

                        RestoreFields();
                    }

                    if($('.add-btn[data-active="true"]').hasClass('add-cancellation')){
                        $('.gf_request_type input[value="Cancel a Scheduled Service"]').trigger('click');

                        SwapFields();
                    }
                }
            });

            $('.gf_request_type input[value="Change a Service"]').bind('click', function(e){
                $('body').find('.select_waste_stream, .number_of_bins, .collection_date').not('.wastestream1 ').addClass("displaynonefieldform");
            });
            $('.gf_request_type input[value="Book a Service"]').bind('click', function(e){
                $('body').find('.select_waste_stream, .number_of_bins, .collection_date').removeClass("displaynonefieldform");
                $('body').find('.collection_date .datepicker,.number_of_bins input ,.select_waste_stream .gfield_select').removeAttr('disabled');
            });

            //Disabling/Enabling upon submitting the form
            if($(".modal-request-a-qoute").find("input[type='submit']").length > 0) {
                var solution_value = $('.customer_type [name="input_152"]');
                var btn_submit = $('.requestaquoteform #gform_submit_button_1');
                solution_value.change(function(){
                    btn_submit.attr('disabled', this.id == "choice_1_152_1");
                    if(this.id == "choice_1_152_1") {
                        btn_submit.addClass('btn-disabled');
                        $('.modal-request-a-qoute').addClass('custom-padding-btn');
                    } else {
                        btn_submit.removeClass('btn-disabled');
                        $('.modal-request-a-qoute').removeClass('custom-padding-btn');
                    }
                });
            }

            if($(".bookchangeaserviceform").find("input[type='submit']").length > 0) {                
                $(".admin-hidden-markup").addClass("displaynonefieldform");
                //make sure all added service has hidden value is true, else should be false when reload/validate
                for(var i=2;i<=9;i++){
                    var block = '.block'+i;
                    if($(block).hasClass('gform_hidden')){
                        $(block).find('.gform_hidden input[type=hidden]').val(true);
                    } else {
                        $(block).next('.gform_hidden').find('input[type=hidden]').val(false);
                    }
                }

                    //Merging of description field of book/change a service form
                    //Get 10 fields service - merged data for salesforce
                    var waname = "", nOb = "", coDate = "", prCallBack = "",merged = [];
                    var wnk = "", megamergedwaste = "", megamerged = "", bookChangeSubject="";
                    $(".bookchangeaserviceform").find("input[type='submit']").click(function() {
                        if($('.gf_request_type input[value="Change a Service"]').is(':checked')){
                            resetDataAllRowService();
                        } else if($('.gf_request_type input[value="Book a Service"]').is(':checked')){
                            addedServices = getBookingServiceAddedFieldsValue(blockAdded);
                            addDataGravityFieldBaseOnCssClass(blockAdded);
                        }
                        
                        jQuery(".googlecid_id").find('input:text').val(ga.getAll()[0].get('clientId'));
                        for (var i = 0; i < 11; i++) {
                            waname = ($('.waname').eq(i).find('select').val()) ? $('.waname').eq(i).find('select').val():'';
                            nOb = ($('.nOb').eq(i).find('input').val()) ? $('.nOb').eq(i).find('input').val():'';
                            coDate = ($('.coDate').eq(i).find('.datepicker').val());

                            
                            if (waname !== null && waname !== ''  && waname!== undefined && waname !== 'Please select') {
                                if(i == 0){
                                    merged.push("Waste Stream: " + waname + ", Quantity/Number of bins: " + nOb + ", Pickup date:" + coDate + "\n\r");  
                                }else{
                                    merged.push("Waste Stream: " + waname + ", Quantity/Number of bins: " + nOb + ", Pickup date:" + coDate + "\n\r");   
                                }
                                 
                            }
                            $('.MergeField').eq(i).find('input').val(merged[i]);
                        }
                        wnk = $('.anythingelsetxt textarea').val();
                        bookChangeSubject = $(".gf_request_type input[type=radio]:checked").val() ? $(".gf_request_type input[type=radio]:checked").val() : "";
                        $('.book-change-subject input').val(bookChangeSubject);
                        prCallBack = $('.prCallBack').find('select').val() ? $('.prCallBack').find('select').val():'';
                        
                        if ($('input[name=input_50]:checked').val() == "Book a Service") {
                            for (var i = 0; i <= merged.length - 1; i++) {
                                megamergedwaste += merged[i];
                            }
                            // prCallBack = ($('.prCallBack').eq(i).find('select').val()) ? $('.prCallBack').eq(i).find('select').val():'';
                            
                            var megamerged = megamergedwaste + "Preferred time of callback: " + prCallBack + "\n\r" + "Anything else we need to know: " + wnk + "\n\r" + "Subject: I want to " + bookChangeSubject ;
                        }else{
                            var megamerged = "Waste Stream: " + $('.block1').find('select').val() + "\n\r" + "Note: " + $('.txtnote').find('textarea').val() + "\n\r" + "Anything else we need to know: " + wnk + "\n\r" + "Preferred time of callback: " + prCallBack  + "\n\r" +  "Subject: I want to " + bookChangeSubject ;
                        }  
                        $('.MegaMerged').find('textarea').val(megamerged);
                    });
                    
                }
                
            });


            //Merging of description field of RequestAQuote and GeneralEnquiries form
            $(document).on('gform_post_render', function(){
                if($(".requestaquoteform").find("input[type='submit']").length > 0) {
                    //merged data for salesforce
            
                    var megamerged = "", megaMerged2024= "";
                    $(".requestaquoteform").find("input[type='submit']").click(function() {
                        // prCallBack = ($('.prCallBack').eq(0).find('select').val()) ? $('.prCallBack').eq(0).find('select').val():'';
                        // var requestFrequencyOfService = $('.frequency-of-service select').val() ? $('.frequency-of-service select').val() : "";
                        wnk = $('.anythingelsetxt textarea').val();
                        var needSolutionForMy = $('.need-solution input[type=radio]:checked').val() ? $('.need-solution input[type=radio]:checked').val() : "";

                        //Added condition to check value of customer type and My Industry field 
                        var customerType = $('.customer_type input[type=radio]:checked').val() ? $('.customer_type input[type=radio]:checked').val() : '';
                        var industry = $('.industry-field select').val() ? $('.industry-field select').val() : "";
                        var myIndustry = '';

                        if(customerType !== 'Existing customer'
                         && industry.length !== 0 
                         && industry !== 'Please select') {
                            myIndustry = 'My industry is: ' + industry + '\n\r';
                        }

                        var megamerged = "Anything else we need to know: " + wnk;
                        var megaMerged2024 = 'I need a solution for my: ' + needSolutionForMy + '\n\r' + myIndustry + 'Anything else we need to know: ' + wnk;
                        
                        $('.MegaMerged').find('textarea').val(megamerged);
                        $('.MegaMerged2024').find('textarea').val(megaMerged2024);
                    }); 
                }

                if($(".generalenquiriesform").find("input[type='submit']").length > 0) {
                    //merged data for salesforce
            
                    var megamerged = "";
                    $(".generalenquiriesform").find("input[type='submit']").click(function() {
                        prCallBack = ($('.prCallBack').eq(0).find('select').val()) ? $('.prCallBack').eq(0).find('select').val():'';
                        var myWasteType = $('.waste-type select').val() ? $('.waste-type select').val() : "";
                        var myEnquiryisAbout = $('.enquiry-service select').val() ? $('.enquiry-service select').val() : "";
                        wnk = $('.anythingelsetxt textarea').val();
                        var exemptedEnquiryisAbout = ['Investor enquiry', 'Job seekers'];

                        myWasteType = !exemptedEnquiryisAbout.includes(myEnquiryisAbout) &&  myEnquiryisAbout != '' ? 'My waste type: ' + myWasteType + '    |    ' : '';
                        
                        if(myEnquiryisAbout != '') {
                            myEnquiryisAbout = 'My Enquiry is about: ' + myEnquiryisAbout + '    |    ';
                        }
                        
                        var megamerged = 'Preferred time of callback:' + prCallBack + '    |    ' + myEnquiryisAbout  + myWasteType + 'Anything else we need to know: ' + wnk;
                        $('.MegaMerged').find('textarea').val(megamerged);

                        
                        //Megamerge 2024
                        var genEnquiriesMegaMerged2024 =  myWasteType + 'Anything else we need to know?: ' + wnk;
                        
                        $('.mega-merge2024').find('textarea').val(genEnquiriesMegaMerged2024);
                    }); 
                }
            });

        

        $('body').find('.add-cancellation').on('click', function(e){
        // add a new cancellation
            e.preventDefault();

            service = parseInt($(this).attr('data-services'), 10);
            if(service < 9) {
                service += 1;
                $(this).attr('data-services', service);

                $('.gform_body .gform_hidden[value=false]').eq(0).val('true');
                $('.gf_request_type input[value="Cancel a Scheduled Service"]').trigger('click');

                SwapFields();
            }
        });

       

        
        // reset book a service form
        $('.gf_request_type input[value="Book a Service"]').bind('click', function(e){
            $('body').find('.select_waste_stream, .number_of_bins, .collection_date').removeClass("displaynonefieldform");
            $('body').find('.select_waste_stream, .number_of_bins, .collection_date').find("input").removeAttr('disabled');
            if($('.add-service1').attr('data-active') === 'false'){

                $('.add-service1').attr('data-active', true);

                $('.add-cancellation').attr('data-active', false);
                $('.add-cancellation').attr('data-services', 0);

                $('.gform_hidden input[value=true]').each(function(index, element){
                    $(element).val('false');
                });

                $('.gform_body .gform_hidden[value=false]').eq(0).val('true');
                $('.gf_request_type input[value="Book a Service"]').trigger('click');
            }
        });

        // reset cancel a service form
        $('.gf_request_type input[value="Cancel a Scheduled Service"]').bind('click', function(e){
            if($('.add-cancellation').attr('data-active') === 'false') {

                $('.add-cancellation').attr('data-active', true);

                $('.add-service1').attr('data-active', false);
                $('.add-service1').attr('data-services', 0);
                $('.gform_hidden input[value=true]').each(function(index, element){
                    $(element).val('false');
                });

                $('.gform_body .gform_hidden[value=false]').eq(0).val('true');
                $('.gf_request_type input[value="Cancel a Scheduled Service"]').trigger('click');
            }
        });

        // centre align captcha
        $('.g-recaptcha').parent().css({ display : 'inline-block', padding : '80px 0px 30px 0px', minHeight : '74px'}).parent().css('text-align', 'center');
    //}
    //end of booking form

    //Css button Add another service

    $( ".btn-blue" ).parent().css({ "text-align" : "center", "margin" : "30px 0px", "padding-top" : "20px" });

    $(".newcust").on("click", function(e){
        $('#modal-3').modal('toggle');
    });

        //Merging of description field of book/change a service form
        //Get 10 fields service
        if($(".bookchangeaserviceform").find("input[type='submit']").length > 0) {
            var waname = "", nOb = "", coDate = "", prCallBack = "", merged = [];
            var wnk = "", megamergedwaste = "", megamerged = "", bookChangeSubject="";
            $(".bookchangeaserviceform").find("input[type='submit']").click(function() { 
                jQuery(".googlecid_id").find('input:text').val(ga.getAll()[0].get('clientId'));

                for (var i = 0; i < 11; i++) {
                    waname = ($('.waname').eq(i).find('select').val()) ? $('.waname').eq(i).find('select').val():'';
                    nOb = ($('.nOb').eq(i).find('input').val()) ? $('.nOb').eq(i).find('input').val():'';
                    coDate = ($('.coDate').eq(i).find('.datepicker').val());
                    if (waname !== null && waname !== ''  && waname!== undefined && waname !== 'Please select') {
                        if(i == 0){
                            merged.push("Waste Stream: " + waname + ", Quantity/Number of bins: " + nOb + ", Pickup date:" + coDate  + "\r\n");  
                        }else{
                            merged.push("Waste Stream: " + waname + ", Quantity/Number of bins: " + nOb + ", Pickup date:" + coDate  + "\r\n");   
                        }  
                    }
                    $('.MergeField').eq(i).find('input').val(merged[i]);
                }

                wnk = $('.anythingelsetxt textarea').val();
                bookChangeSubject = $(".gf_request_type input[type=radio]:checked").val() ? $(".gf_request_type input[type=radio]:checked").val() : "";
                $('.book-change-subject input').val(bookChangeSubject);

                prCallBack = $('.prCallBack').find('select').val() ? $('.prCallBack').find('select').val():'';

                if ($('input[name=input_50]:checked').val() == "Book a Service") {

                    for (var i = 0; i <= merged.length - 1; i++) {
                        megamergedwaste += merged[i];
                    }
                    // prCallBack = ($('.prCallBack').eq(i).find('select').val()) ? $('.prCallBack').eq(i).find('select').val():'';
                    
                    var megamerged = megamergedwaste + "Preferred time of callback:" + prCallBack + "\r\n" + "Anything else we need to know: " + wnk + "\r\n" + "Subject: I want to " + bookChangeSubject ;
                }else{
                    var megamerged = "Waste Stream: " + $('.block1').find('select').val() + "\r\n" + "Note: " + $('.txtnote').find('textarea').val() + "\r\n" + "Anything else we need to know: " + wnk + "\r\n" + "Preferred time of callback:" + prCallBack + "\r\n" + "Subject: I want to " + bookChangeSubject ;
                }   
                $('.MegaMerged').find('textarea').val(megamerged);
            });
        }

    if($(".requestaquoteform, .dardanupform, .generalenquiriesform, .asxsubscribe, .subcribeform, .talktousform, .decontaminationform, .greasetrapform, .wasteBrokersRequest, .christmasNewYearService, .sendusamessage").find("input[type='submit']").length > 0) {
        $(".requestaquoteform, .dardanupform, .generalenquiriesform, .asxsubscribe, .subcribeform, .talktousform, .decontaminationform, .greasetrapform, .wasteBrokersRequest, .christmasNewYearService,  .sendusamessage").find("input[type='submit']").click(function() { 
            jQuery(".googlecid_id").find('input:text').val(ga.getAll()[0].get('clientId'));
    
        });
     }

    //edit UI for Dardanup Form
    //$(".checkboxshowtime ul li label").after("<p>Depart from Dardanup Hall <br> Return no later than</p>" +);
    $('.checkboxshowtime ul li label').each(function(i, v) {
        var getValueTime = $(this).prev().val();
        $(this).after("<p>Depart from Dardanup Hall <br> Return no later than " + getValueTime + "</p>");
    });
    $('.checkboxshowtime ul li').addClass("text-center");


    /**
     * Author: Heidee Sia
     * Description: Waste Brokers Request Page merge values
     * 
    */
    if($(".wasteBrokersRequest").find("input[type='submit']").length > 0) {
        //Declare variables for all the fields
        var merged=[], conSendEmails = "", division = "", state = "", requestType = "", wasteStream = "", proposedDateAdditionalServices = "", dateofServiceCancelled = "", startSuspensionDate = "", endSuspensionDate = "", reasonSuspension = "", binSize = "", collectionDate = "", anythingElseDetails = "", listChangesRequired = "", startDateService = "", frequencyServiceRequired = "";

        // Set minDate and dateFormat for all waste brokers request datepicker
        $('.wasteBrokersRequest .datepicker').datepicker({ 
            minDate: 0,
            dateFormat: 'dd/mm/yy'
        });

        //Start suspension date and End Suspension date
        $('.start_date_suspension .datepicker').change(function() {
            var startDate = $(this).datepicker('getDate', '+1d');
            if(startDate) {
                startDate.setDate(startDate.getDate() +1);
            }
            $(".end_date_suspension .datepicker").datepicker("option", "minDate", startDate );
        });

        $('.end_date_suspension .datepicker').change(function() {
            var endDate = $(this).datepicker('getDate', '-1d');
            if(endDate) {
                endDate.setDate(endDate.getDate() -1);
            }
            $(".start_date_suspension .datepicker").datepicker("option", "maxDate", endDate );
        });

       //Merge Paragraph
        $(".wasteBrokersRequest").find("input[type='submit']").on('click', function() {
            //Assign values of inputs to variables
            requestType = $('.request_type input[type=radio]:checked').val() ? $('.request_type input[type=radio]:checked').val() : "";
            proposedDateAdditionalServices = $('.date_add_services input').val() ? $('.date_add_services input').val() : "";
            anythingElseDetails = $('.anything_else_details textarea').val() ? $('.anything_else_details textarea').val() : "";
            dateofServiceCancelled = $('.date_service_cancelled input').val() ? $('.date_service_cancelled input').val() : "";
            collectionDate = $('.date_collection input').val() ? $('.date_collection input').val() : "";
            frequencyServiceRequired= $('.frequency_required input').val() ? $('.frequency_required input').val() : "";
            startDateService = $('.start_date_service input').val() ? $('.start_date_service input').val() : "";
            state = $('.state select').val() ? $('.state select').val() : "";
            division = $('.division input[type=radio]:checked').val() ? $('.division input[type=radio]:checked').val() : "";
            listChangesRequired = $('.list_changes_required textarea').val() ? $('.list_changes_required textarea').val(): "";
            startSuspensionDate = $('.start_date_suspension input').val() ? $('.start_date_suspension input').val() : "";
            endSuspensionDate = $('.end_date_suspension input').val() ? $('.end_date_suspension input').val() : "";
            reasonSuspension = $('.reason_suspension textarea').val() ? $('.reason_suspension textarea').val(): "";

            $('.waste_stream select').each(function(){
              if(this.value){
                wasteStream = this.value;
                return false;
              }
            });

            $('.bin_size input').each(function(){
              if(this.value){
                binSize = this.value;
                return false;
              }
            });

            //Book an additional services
            if(requestType == 'Book an additional services') {
                merged.push(`Waste Stream: ${wasteStream}, Proposed date of Additional services: ${proposedDateAdditionalServices}, Anything else we need to know: ${anythingElseDetails}`);
            }

            //Cancel a single service
            if(requestType == 'Cancel a single service') {
                merged.push(`Waste Stream: ${wasteStream}, Date of service to be cancelled: ${dateofServiceCancelled}, Anything else we need to know: ${anythingElseDetails}`);
            }

            //Suspend services for a period of time
            if(requestType == 'Suspend services for a period of time') {
                merged = [];
                merged.push(`Waste Stream: ${wasteStream}, Start date of suspension: ${startSuspensionDate}, End date of suspension: ${endSuspensionDate}, Reason for suspension: ${reasonSuspension}, Anything else we need to know: ${anythingElseDetails}`);
            }

            //Changes to existing services and Change of Ownership request 
            if(requestType == 'Changes to existing services' || requestType == 'Cancel account or services permanently') {
                merged = [];
                merged.push(`Waste Stream: ${wasteStream}, Bin container size: ${binSize}, Please list the change/s required: ${listChangesRequired}, Anything else we need to know: ${anythingElseDetails}`);
            }

            //Request a quote and Change of Ownership request type
            if(requestType == 'Request a quote' || requestType == 'Change of ownership') {
                merged = [];
                merged.push(`Waste Stream: ${wasteStream}, Bin container size: ${binSize}, Start date of first service: ${startDateService}, Frequency of service required: ${frequencyServiceRequired}, Anything else we need to know: ${anythingElseDetails}`);
            }

            //Get url of the form being submitted
            if($(".embed_url_quote").length > 0) {
                var currentURL = location.protocol + '//' + location.host + location.pathname;
                $('body').find(".embed_url_quote").find('input:text').val(currentURL);
            }

            //Assign values to hidden input fields
            $(".mega_merged textarea").val(merged);
            $(".condition_send_emails textarea").val(`${division} - ${state}`);
        });
    }


    /**
     * Author: Heidee Sia
     * Description: Christmas and New Year Service merge values
     * 
    */
    if($(".christmasNewYearService").find("input[type='submit']").length > 0) {
        //Declare variables for all the fields
        var merged=[], selectService="", serviceReschedule="", otherRequirements="", listAdditionalRequirements="", fromDate="", toDate="";

        var fromDateField = $(".from_date .datepicker");
        var toDateField = $(".to_date .datepicker");
        fromDateField.datepicker('destroy');
        toDateField.datepicker('destroy');
        // Set minDate and dateFormat for dates from and to of select service option
        setDatePickerChirtmasForm();
        
        //Start suspension date and End Suspension date
        fromDateField.change(function() {
            setMinDateForToDate(fromDateField, toDateField);
        });

        toDateField.change(function() {
            setMaxDateForFromDate(fromDateField, toDateField);
        });


       //Merge Paragraph
        $(".christmasNewYearService").find("input[type='submit']").on('click', function() {
            //Assign values of inputs to variables
            serviceReschedule = $('.service_reschedule select').val() ? $('.service_reschedule select').val() : "";
            otherRequirements = $('.other_requirements textarea').val() ? $('.other_requirements textarea').val(): "";
            fromDate = $('.from_date input').val() ? $('.from_date input').val() : "";
            toDate = $('.to_date input').val() ? $('.to_date input').val() : "";
            listAdditionalRequirements = $('.list_additional_services textarea').val() ? $('.list_additional_services textarea').val() : "";

            $('.select_service select').each(function(){
              if(this.value){
                selectService = this.value;
                return false;
              }
            });

            //Get url of the form being submitted
            if($(".embed_url_quote").length > 0) {
                var currentURL = location.protocol + '//' + location.host + location.pathname;
                $('body').find(".embed_url_quote").find('input:text').val(currentURL);
            }

            //clear field
            merged = [];

            //Merged when selected services has I need additional services value
            if(selectService.indexOf('I need additional services') >= 0 && serviceReschedule){
                merged.push(`Please select one of the following: ${selectService}, List of additional services: ${listAdditionalRequirements}, If your service day falls on a public holiday, would like you like your service to be rescheduled?: ${serviceReschedule}, Other requirements: ${otherRequirements}`);
            }

            //Merged when selected services has I will be CLOSED during value
            if(selectService.indexOf('I will be CLOSED during') >= 0 && serviceReschedule){
                merged.push(`Please select one of the following: ${selectService}, From: ${fromDate}, To: ${toDate}, If your service day falls on a public holiday, would like you like your service to be rescheduled?: ${serviceReschedule}, Other requirements: ${otherRequirements}`);
            }

            //Merged when selected services has Please CONTINUE my services and I will be CLOSED over value
            if((selectService.indexOf('Please CONTINUE my services') >= 0 || selectService.indexOf('I will be CLOSED over')) >= 0 && serviceReschedule) {
                merged.push(`Please select one of the following: ${selectService}, If your service day falls on a public holiday, would like you like your service to be rescheduled?: ${serviceReschedule}, Other requirements: ${otherRequirements}`);
            }

            //Assign values to hidden input fields
            $(".mega_merged textarea").val(merged);
        });
    }

    if($(".resignCampaign").find("input[type='submit']").length > 0) {
        let merged=[];

        //Merge paragraph for resignCampaign 
        $(this).find("input[type='submit']").on('click', function() {
            leadOwnerID = $('.lead_owner_id input').val() ? $('.lead_owner_id input').val() : "";
            formTitle = $('.form_title input').val() ? $('.form_title input').val() : "";
            accountName = $('.account_name input').val() ? $('.account_name input').val() : "";
            accountNumber = $('.account_number input').val() ? $('.account_number input').val() : "";
            firstName = $('.first_name input').val() ? $('.first_name input').val() : "";
            lastName = $('.last_name input').val() ? $('.last_name input').val() : "";

            //Get url of the form being submitted
            if($(".embed_url_quote").length > 0) {
                $('body').find(".embed_url_quote").find('input:text').val(window.location.href);
            }
            

                merged.push(`Lead Owner ID:  ${leadOwnerID}, Form title: ${formTitle}, Account Name: ${accountName}, Account Number: ${accountNumber}, Name: ${firstName} ${lastName}`);
            
                //Assign values to hidden input fields
                $(".mega_merged textarea").val(merged);
            });
        }
           
        
        $(document).on('gform_post_render', function(){
            //Add in generalenquiriesform record type, subject field and type field.
        
            if($(".generalenquiriesform").find("input[type='submit']").length > 0) {
                
                var recordTypeID="", valueType="", enquiryService= "";
                var subjectMergeValues = "", wasteType = "";
            

                $(this).find("input[type='submit']").on('click', function() {
                    enquiryService = $('.enquiry-service select').val() ? $('.enquiry-service select').val() : "";
               
                    if (enquiryService === 'Complaint') {
                        recordTypeID = $('.complaintRecordTypeID input').val();
                    } else if(enquiryService === 'Dispute') {
                        recordTypeID = $('.disputeRecordTypeID input').val();
                    } else {
                        recordTypeID = $('.notPCDRecordTypeID input').val(); 
                    }

                    valueType = (enquiryService === 'Payment' ? 'Finance' : '');
                    wasteType = $(".waste-type select").val() ? $(".waste-type select").val() : "";
                    subjectMergeValues = "My enquiry is about " + enquiryService + " and My waste type is " + wasteType;


                //Assigning values to hidden inputs
                $(".record-type input").val(recordTypeID);
                $(".subject input").val(subjectMergeValues);
                $(".type input").val(valueType);


                //Full name merge combine first name and last name
                var firstName = $('.gtm-first-name input').val() ? $('.gtm-first-name input').val() : '';
                var lastName = $('.gtm-last-name input').val() ? $('.gtm-last-name input').val() : '';
                var fullName = firstName + ' ' + lastName;

                $('.full-name input').val(fullName);
            });
        }
    });

    //Missed Service Rebooking Megamerged Paragraph
    $(document).on('gform_post_render', function(){

        if($(".missed-service-rebooking").find("input[type='submit']").length > 0) {      
            var megamerged = "", wasteType = "", anythingElseDetails = "", preferredTimeCallBack = "", subject = "";

            $(this).find("input[type='submit']").on('click', function() {
                wasteType = $('.gf-waste-type select').val() ? $('.gf-waste-type select').val() : '';
                anythingElseDetails = $('.gf-anything-else textarea').val() ? $('.gf-anything-else textarea').val()  : '';
                preferredTimeCallBack = $('.gf-time-call-back select').val() ? $('.gf-time-call-back select').val():''; 
                subject = $('.gf-i-want-type input[type=radio]:checked').val() ? $('.gf-i-want-type input[type=radio]:checked').val() : '';

                $('.gf-subject input').val(subject); 
                
                megamerged = "Waste Type: " + wasteType + " | Anything else we need to know: " + anythingElseDetails + " | Preferred time of callback: " + preferredTimeCallBack + " | Subject: " + subject;
                $('.gf-mega-merged textarea').val(megamerged);
            });
        }
    });

    //fix form formDidWeMissSomething ID=28 resubmitted when click goback from thank-you page
    if($('.formDidWeMissSomething').length > 0){
        $(document).on('gform_post_render', function(e,form_id){
            $('#gform_ajax_frame_'+form_id).attr('src','about:blank');
        });
    }

    //get block order by css class name
    function getCurrentBlockIndex(blockClassName){
        return blockClassName[blockClassName.length-1];
    }

    //re-order blocks by changing css class
    function renameBlockClass(blockIndex){                
        for(var i=parseInt(blockIndex); i<=9;i++){
            var nextBlockIndex = i+1;
            $('.block'+nextBlockIndex).children('.remove-service').attr('data-div','block'+i);
            $('.block'+nextBlockIndex).addClass('block'+i).removeClass('block'+nextBlockIndex);
        }        
    }

    //re-index added blocks
    function reindexBlockAdded(blockAdded){
        var newBlockAdded = []
        for(let i=0;i<blockAdded.length;i++){
            newBlockAdded.push('.block'+(i+2));
        }
        return newBlockAdded;
    }

    function setMaxDateForFromDate(fromDate, toDate){
        var endDate = toDate.datepicker('getDate', '-1d');
        var today = new Date();
        if(formatDate(today) === toDate.val()){
            fromDate.val("");
        }
        if(endDate) {
            endDate.setDate(endDate.getDate() -1);
        }
        fromDate.datepicker("option", "maxDate", endDate );
    }

    function setMinDateForToDate(fromDate, toDate){
        var startDate = fromDate.datepicker('getDate', '+1d');
        if(startDate) {
            startDate.setDate(startDate.getDate() +1);
        }
        toDate.datepicker("option", "minDate", startDate );
    }

    function setDatePickerChirtmasForm(){
        if($(".christmasNewYearService").find("input[type='submit']").length > 0) {
            var fromDateField = $(".from_date .datepicker");
            var toDateField = $(".to_date .datepicker");
            var today = new Date();
            today.setDate(today.getDate()+1);
            fromDateField.datepicker({ 
                minDate: 0,
                dateFormat: 'dd/mm/yy'
            });
            toDateField.datepicker({ 
                minDate: today,
                dateFormat: 'dd/mm/yy'
            });
            if(toDateField.val()) {
                setMaxDateForFromDate(fromDateField, toDateField);
            }
    
            if(fromDateField.val()){
                setMinDateForToDate(fromDateField, toDateField);
            }
        }
    }
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    
    function formatDate(date) {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    //save field value to object data
    function getBookingServiceAddedFieldsValue(blockAdded){
        var addedService = [];
        for (var i = 0; i < blockAdded.length; i++) {
            addedService.push({
                'blockClass': blockAdded[i],
                'waname':$(blockAdded[i]).find('.gfield_select').find('option:selected').text(),
                'nOb': $(blockAdded[i]).find('input[type=number]').val(),
                'date':$(blockAdded[i]).find('input.datepicker').val()
            });
        }
        return addedService;
    }

    //set data for gravity fields with current css Class
    function addAddedServiceDataToGravityFields(blockAdded){
        for (var i = 0; i < blockAdded.length; i++) {
            $(blockAdded[i]).removeClass('gfield_visibility_hidden');
            $(blockAdded[i]).addClass('gfield_visibility_visible');
            $(blockAdded[i]).find('.validation_message').remove();
            addDataToGravityFieldByIndex(i);
        }
        for(var i= blockAdded.length+2; i< 10; i++){
            resetRowService('.block'+i);
        }
    }

    //add data added service to gravity field 
    function addDataToGravityFieldByIndex(index){
        if(addedServices[index]){
            $(blockAdded[index]).find(".gfield_select option:selected").prop('selected',false);
            $(blockAdded[index]).find(".gfield_select option[value='" + addedServices[index].waname+"']").prop('selected',true);
            $(blockAdded[index]).find("input[type=number]").val(addedServices[index].nOb);           
            $(blockAdded[index]).find(".datepicker").val(addedServices[index].date);
        }
    }

    // map row data in order by css class .rowService
    // don't use class .block because it's changed when add/remove a row
    function addDataGravityFieldBaseOnCssClass(){
        for (var i = 0; i < blockAdded.length; i++) {
            var block = '.rowService'+(i+2);
            $(block).removeClass('gfield_visibility_hidden');
            $(block).addClass('gfield_visibility_visible');
            $(block).find('.validation_message').remove();            
            if(addedServices[i]){
                $(block).find(".gfield_select option:selected").prop('selected',false);
                $(block).find(".gfield_select option[value='" + addedServices[i].waname+"']").prop('selected',true);
                $(block).find("input[type=number]").val(addedServices[i].nOb);
                $(block).find("input.datepicker").val(addedServices[i].date);
                $(block).find('input.datepicker').datepicker('setDate', addedServices[i].date);
            }
        }
        for(var i= blockAdded.length+2; i< 10; i++){
            var block = '.rowService'+i;
            $(block).removeClass('gfield_visibility_visible');
            $(block).removeClass('gfield_error');
            $(block).addClass('gfield_visibility_hidden');
            $(block).find('.validation_message').remove();
            resetRowService('.rowService'+i);
        }
    }

    //reset field data when remove a service row
    function resetRowService(block){
        $(block).find('input.gform_hidden[value=true]').val(false);
        $(block).find('.validation_message').remove();
        $(block).find('input.datepicker').datepicker('setDate', null);
        $(block).find('input[type=number]').val('');
        $(block).find(".gfield_select option:selected").prop('selected',false);
        $(block).find(".gfield_select option[value='Please select']").prop('selected',true);
    }

    //clear all field data
    function resetDataAllRowService(){
        for(var i= 2; i< 10; i++){
            resetRowService('.rowService'+i);
        }
    }
    setDatePickerChirtmasForm();
});

$('.gf_request_type input[value="Change a Service"]').bind('click', function(e){
    $('body').find('.select_waste_stream, .number_of_bins, .collection_date').not('.wastestream1 ').addClass("displaynonefieldform");
});

/**
 * Author: Hung Nguyen
 * Description: JS for the Track Smart page
 * 
*/
if($(".tracksmartIV").find("input[type='submit']").length > 0) {
        //Grant value for forms
        var source = $("#utm_source").val();
        var medium = $("#utm_medium").val();
        var campaign = $("#utm_campaign").val();
        var content = $("#utm_content").val();
        var term = $("#utm_term").val();    
        var campaignid = $("#campaign_id").val();
        
        var task_site_code = $("#task_site_code").val();
        var task_site_state = $("#task_site_state").val();
        var task_site_name = $("#task_site_name").val();
        var task_site_address = $("#task_site_address").val();
        var task_site_contact_name = $("#task_site_contact_name").val();
        var task_site_email_current = $("#task_site_email_current").val();

        $(".task_site_code input").val(task_site_code);
        $(".task_site_state input").val(task_site_state);
        $(".task_site_name input").val(task_site_name);
        $(".task_site_address input").val(task_site_address);
        $(".task_site_contact_name input").val(task_site_contact_name);
        $(".task_site_email_current input").val(task_site_email_current);

}

if($(".newchum").find("input[type='submit']").length > 0) { 
    jQuery(".embed_url").find('input:text').val();
    jQuery(".lead_source").find('input:text').val();

}
// Parse the URL

// var source = $("#utm_source").val();
// var medium = $("#utm_medium").val();
// var campaign = $("#utm_campaign").val();
// var content = $("#utm_content").val();
// var term = $("#utm_term").val();
// var landingpage = $("#landing_page").val();
// var campaignid = $("#campaign_id").val();


// $('body').find("li.utm_source input").attr('value', source);
// $('body').find(".utm_medium input").attr('value', medium);
// $('body').find(".utm_campaign input").attr('value', campaign);
// $('body').find(".utm_content input").attr('value', content);
// $('body').find(".utm_term input").attr('value', term);
// $('body').find(".landing_page input").attr('value', landingpage);
// $('body').find(".campaign_id input").attr('value', campaignid);
