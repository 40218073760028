$(document).ready(function($) {
	//create variable for all step
	var getNextStepId = 0, getStepCu = 0, getBackStepId = 0;
	var objectNutureParent = "", serviceSelect = [], getServiceName = [];
	var siteBoxes = [];
	var percentProcess = 0;
	var getNameNatureString = "";
	var sitesData = {}, siteData = [];
	sitesData.siteData = siteData;

	var counterCallAjax=0;

	//push from CSV file
	var natureData = []; 
	var serviceData = []; 
	var objectServiceParent = []; 

	var natureIdService = "";

	//hard core Id for all step, and will hard by class 
	var idStep1_basicDetails = 1;
	var idStep2_manySites = 2;
	var idStep3_moreAboutSize = 3;
	var idStep4_lookingFor = 4;
	var idStep5_natureBusiness = 5;
	var idStep6_serviceBusiness = 6;
	var idStep7_question = 7;
	var idStep8_cusomerInformation = 8;
	var idStep9_summary = 9;
	var idStep10_done = 10;

	jQuery.ajaxSetup({
	  beforeSend: function() {
	     $("#loadingAjax").removeClass("hidden");
	  },
	  complete: function(){
	     $("#loadingAjax").addClass("hidden");
	  },
	  success: function() {}
	});

	//hard gravity form 
	$("#submitgravity").addClass("hidden");
	$(".filedsummarysalesforce ").addClass("hidden");
	$(".filedsummarysalesforce").find("input").attr("disabled", "disabled");


	//Create validation for form

	$('#step_infoBusiness').validate({
		highlight: function(element) {
		    $(element).parent().addClass('valid-error').removeClass('valid-success');
		},
		unhighlight: function(element) {
		    $(element).parent().removeClass('valid-error').addClass('valid-success');
		},
		//errorLabelContainer: '#error_alert',
		errorElement: 'div',
    	errorClass: 'alert alert-danger',
		errorPlacement: function(error, element) {
	     	error.appendTo('#error_alert');
	   	}
	});
	function addRuleValidationClass(){
		$("body").find(".inputValidate").each(function (item, input) {
			$(this).rules('add', {
			  required: true,
			  messages: {
			    required: function(element){
			    	if (input.placeholder && $(input).attr("data-siteId")) {
			    		return "<strong>"+ $(input).attr("data-siteId") + "</strong>" + ": Please enter your " + input.placeholder;
			    	}else if(input.placeholder && !$(input).attr("data-siteId")){
			    		return "Please enter your " + "<strong>"+input.placeholder+"</strong>";
			    	}else{
			    		return "<strong>" + $(input).attr("data-siteId") +"</strong>" + ": Please enter your " + $(input).attr("data-messages");
			    	}
			    },
			  }
			});
		});
		$("body").find(".emailValidate").each(function (item, input) {
			$(this).rules('add', {
			  required: true,
			  email: true,
			  messages: {
			    required: "Please enter your " + "<strong>"+input.placeholder+"</strong>",
			    email: "<strong>"+input.placeholder+"</strong> is not correct",
			  }
			});
		});
		$("body").find("#natureValid").each(function (item, input) {
			$(this).rules('add', {
			  required: function (element) {
	                var boxes = $('.natureSelected');
	                if (boxes.filter(':checked').length == 0) {
	                    return true;
	                }
	                return false;
	          },
			  messages: {
			    required: "Please select at least one anture",
			  }
			});
		});
		$("body").find("#serviceValid").each(function (item, input) {
			$(this).rules('add', {
			  required: function (element) {
	                var boxes = $('.servicesSelected');
	                if (boxes.filter(':checked').length == 0) {
	                    return true;
	                }
	                return false;
	          },
			  messages: {
			    required: "Please select at least one service",
			  }
			});
		});


	}
	addRuleValidationClass();

	//function disabled Site 
	function disableSiteWithLoop() {
		for (var i = 1; i <= siteBoxes.length; i++) {
			var setIdSite = "#" + siteBoxes[i];
			$(setIdSite).find("input, button, submit, textarea, select").attr("disabled", "disabled");
			$(setIdSite+" div").first().addClass("solution-finder__disabled-box");
		}
	}
	function undisableSiteWithLoop(){
		for (var i = 0; i < siteBoxes.length; i++) {
			var setIdSite = "#" + siteBoxes[i];
			$(setIdSite).find("input, button, submit, textarea, select").removeAttr("disabled");
			$(setIdSite+" div").first().removeClass("solution-finder__disabled-box");
		}
	}


	function nextStep() {
		addRuleValidationClass();
	    if($("#step_infoBusiness").valid()){
	    	//console.log(siteBoxes);
	    	getStepCu = parseInt($("#btnsub").attr('data-step'), 10);
	    	if(getStepCu < 10){
	    		//update % progress bar
	    		percentProcess = parseInt($("#btnsub").attr("data-step")) * 12;
	    		$(".progress__bar").css({"width": percentProcess+"%"});
	    		//get option idea 
	    		var typeOfEdia = $("#natuneplace").attr("data-typeidea");
	 
	    		//next step + 1 step
	    		getNextStepId = getStepCu + 1;

	    		//custom type for the looking for page
	    		if(getNextStepId == idStep7_question && typeOfEdia == "No"){ //custom step question		
	    			getNextStepId = getNextStepId + 1;
	    		}else{
	    			CreateQuestionHTML("#questionPlace");
	    		}

	    		$("#btnsub").attr('data-step', getNextStepId);
	    		$(".step_" + getStepCu).addClass("hidden");
	    		$(".step_" + getNextStepId).removeClass("hidden");
	    		$(".btnback").removeClass("hidden");
	    		var getBoxCur = $("#lookingplace").attr("data-boxsite");
	    		//hidden btn Submit on step
	    		if (getNextStepId == idStep2_manySites || getNextStepId == idStep3_moreAboutSize || getNextStepId == idStep4_lookingFor) {
	    			$("#btnsub").addClass("hidden");
	    		}else if(getNextStepId == idStep6_serviceBusiness){ //custom step select the services 
					CreateServicesHTML("#servicesplace",getBoxCur,"servicesSelected");
	    		}else if(getNextStepId == idStep8_cusomerInformation){ //custom step summary
	    			//get value for current box site

	    			var getPostCode = $("#"+getBoxCur).find(".postcodeInput").val();
	    			var getBusiness = $("#"+getBoxCur).find(".businessI").val();
	    			//
	    			var getFirstNameCus = $(".firstNamePage1").val();
	    			var getEmailCus = $(".emailregister").val();
	    			$(".firstnamecustomer").val(getFirstNameCus);
	    			$(".emailcustomer ").val(getEmailCus);

	    			//remove class for box detail 
	    			$("#stepNatureDetail").removeClass("step_5").addClass("hidden");
	    			$("#stepServiceDetail").removeClass("step_6").addClass("hidden");

	    			//remove current siteBox to siteBoxes array
	    			removeArrByString(siteBoxes, getBoxCur);

	    			//check how many site in siteBoxes array
	    			if (siteBoxes.length > 0) {
	    				//undisable and recall disable
	    				undisableSiteWithLoop();
	    				disableSiteWithLoop();
	    				//console.log(siteBoxes);
	    				$("#"+getBoxCur).addClass("hidden");
	    				$(".step_8").addClass("hidden");	    				
	    				$(".step_3").removeClass("hidden");
	    				//hidden button next when back to step 3
	    				$("#btnsub").attr('data-step', 3).addClass("hidden");
	    				$("#btnback").addClass("hidden");

	    				var getNatureString = getNameNatureString.toString();
	    				var getService = getServiceName.toString();
	    				createNewSite(getBoxCur, getPostCode, getBusiness, getNatureString,getService);
	    				getServiceName = [];
	    				getNameNatureString = "";
	    				//console.log(JSON.stringify(sitesData));
	    				//reset data nature
	    				natureData = [];
	    				objectNutureParent = "";
	    				serviceData = [];
	    				//objectServiceParent = [];
	    				natureIdService = "";
	    				serviceSelect = [];
	    				//console.log(serviceSelect);
	    			}else{
	    				//alert("het box");
	    				//console.log(getNameNatureString);
	    				var getNatureString = getNameNatureString.toString();
	    				var getService = getServiceName.toString();
	    				createNewSite(getBoxCur, getPostCode, getBusiness, getNatureString, getService);
	    				//CreateSummaryHTML("#accordion",siteData);
	    			}
	    		}else if(getNextStepId == idStep9_summary){//custom step summary
	    			CreateSummaryHTML("#accordion",siteData);
	    			$("#btnback").addClass("hidden");
	    			$("#btnsub").addClass("hidden");

	    			//$("#submitgravity").removeClass("hidden");
	    			$(".filedsummarysalesforce").find("input").prop("disabled", false);
					$(".filedsummarysalesforce").find("input").val("123123");

	    			//$(".filedsummarysalesforce").find("input").attr("disabled", "false");

	    		}else if(getNextStepId == idStep10_done){
	    			$("#btnback").addClass("hidden");
	    			$("#btnsub").addClass("hidden");
	    		}
	    		else{
	    			$("#btnsub").removeClass("hidden");
	    		}
	    	}
	    }else{
	    	//alert("not valid!");
	    }
	}

	//Button Next Step
	$('#btnsub').on('click', function() {
		nextStep();
	});

	//Button Back Step
	$('#btnback').on('click', function() {
		getNextStepId -= 1; //back 1 step
		getStepCu = parseInt($("#btnsub").attr('data-step'), 10);
		getStepCu !== 1 ? $("#btnsub").removeClass("hidden") : ""; //unhide btn submit 
		if(getStepCu > 1){
			getBackStepId = getStepCu - 1;
			//update % progress bar
			percentProcess = (getStepCu - 2) * 12;
			$(".progress__bar").css({"width": percentProcess+"%"});


			if (getStepCu == 8) {
				getBackStepId = getBackStepId - 1;
				
				siteData.splice(-1,1);
				console.log(sitesData.siteData);

			}
			$("#btnsub").attr('data-step', getBackStepId);
			$(".step_" + getStepCu).addClass("hidden");
			$(".step_" + getBackStepId).removeClass("hidden");
			getBackStepId == 1 ? $("#btnback").addClass("hidden") : ""; //hidden btn Back when step = 1
			//emply object SiteBox when back to step 2
			if (getBackStepId == idStep2_manySites) {
				siteBoxes = [];
				$("#error_alert").html("");
			}
			if (getBackStepId == idStep3_moreAboutSize) {
				//Clear site when back step
				siteBoxes = [];
				sitesData = {}, siteData = [];
				sitesData.siteData = siteData;
			}
			if (getBackStepId == idStep7_question) {
				siteData.pop();
			}
			if (getBackStepId == idStep2_manySites || getBackStepId == idStep3_moreAboutSize || getBackStepId == idStep4_lookingFor) {
				natureData = [];
    			$("#btnsub").addClass("hidden");
    		}else{
				$("#btnsub").removeClass("hidden"); 
    		}
		}
	   	//Remove Steps have been created in dynamic
		switch (getStepCu) {
 	    	case idStep5_natureBusiness:
		        objectNutureParent = "";    
		        $("#stepNatureDetail").removeClass("step_5");
		        $('.step_detail').remove();
	    	break;
	    	case idStep6_serviceBusiness:
	    		serviceSelect = [];
	    		$("#stepServiceDetail").removeClass("step_6");
	    		//$('.step_detail').remove();
	    	break;
		}
	});

	//function for users choose site
	$('.siteSelected').on('click', function() {
		var siteValue = $(this).attr('data-site');
		nextStep();
		if(siteValue < 6){
			CreateSiteHTML(siteValue,"#siteplace");
			addRuleValidationClass();
			disableSiteWithLoop();
		}else{
			$("#siteplace").html('<p>More than 5 pages</p>')
		}

	});

	//function create object for Site
	function createNewSite(nameSite ,postCode, businessSize, naturebusiness, service) {
		oneSiteData = {
			"namesitebox" : nameSite,
			"postcode" : postCode,
			"businesssize" : businessSize,
			"naturebusiness" : naturebusiness,
			"service" : service
		};
		sitesData.siteData.push(oneSiteData);
	}


	//Function for JS create Sites
	function CreateSiteHTML(number,wherehtml){

		var container = $('<div class="row row-eq-height" />');
		for (var i = 1; i <= number; i++) {
			var siteHtml = '<div id="boxSite'+i+'" class="boxSiteCss col-md-6 col-xs-12">';
			siteHtml += '<div class="solution-finder__white-box">';
			siteHtml += '<div class="title-group">';
			siteHtml += '<span class="title">Site ' + i +'</span>';
			if (i > 1) {
				siteHtml += '<span class="icon icon-delete btnDeteleSite" name="confirm-delete" data-toggle="modal" data-target="#confirm-delete-modal"></span>';
			}
			siteHtml += '</div><div class="input-group"><span class="input-group-addon"><i class="icon icon-calendar"></i></span>';
			siteHtml += '<input data-siteId="Site '+i+'" name="Postcode'+i+'" class="inputValidate textfield textfield__full postcodeInput" type="number" onkeydown="return event.keyCode !== 69" placeholder="Postcode"></div>';
			siteHtml += '<div class="input-group"><span class="input-group-addon"><i class="icon icon-calendar"></i></span>';
			siteHtml += '<input data-siteId="Site '+i+'" name="streetaddress'+i+'" class="inputValidate textfield textfield__full streetaddressInput" type="text" placeholder="Street Address"></div>';
			//siteHtml += '<input class="inputValidate textfield textfield__full businessI" name="businessSize'+i+'" type="text" placeholder="Business size"></div></div>';
			siteHtml += '<div class="custom-dropdown"><select data-siteId="Site '+i+'" data-messages="Business size" class="inputValidate custom-dropdown__select businessI" name="businessSize'+i+'"><option selected disabled>Business Size</option><option>Size 1</option><option>Size 2</option><option>Size 3</option> <option>Size 4</option><option>Size 5</option></select></div></div></div>';
			container.append(siteHtml);
			siteBoxes.push("boxSite"+i);
		}
		return $(wherehtml).html(container);
	}


	function CreateNaturnServicesHTML(wherehtml, setIdBox, classCheckBox, objectNature){
		var container = $('<div class="test" />');
		for (var i = 0; i < objectNature.length; i++) {
			if (objectNature[i].ParentId == 0) {
				var naturnHTML = '<div data-boxId="'+setIdBox+'" data-service="'+objectNature[i].ServiceId+'" class="col-md-6 col-xs-12"><input id="'+objectNature[i].ID+'" class="'+classCheckBox+'" type="checkbox" name="nature'+i+'" value="'+objectNature[i].Name+'">';
				naturnHTML += '<label for="'+objectNature[i].ID+'" class="inputValidate button button--blue white-border check-item">'+objectNature[i].Name+'<i class="icon icon-check"></i></label></div>';
				container.append(naturnHTML);	
			}
		}
		return $(wherehtml).html(container);
	}

	function CreateServicesHTML(wherehtml, setIdBox, classCheckBox){
		var checkId = [];
		var container = $('<div class="test" />');
		for (var i = 0; i < serviceData.length; i++) {
			//for (var j = 0; j < natureIdService.length; j++) {
				var getId = natureIdService;
				if (getId.includes(serviceData[i].ID) == true) {
					if (serviceData[i].Name == serviceData[i].Name && checkId.includes(serviceData[i].ID) == false) {
						//console.log(serviceData[i].Name);
						var naturnHTML = '<div data-boxId="'+setIdBox+'" data-service="'+serviceData[i].ID+'" class="col-md-6 col-xs-12"><input id="'+serviceData[i].ID+'" class="'+classCheckBox+'" type="checkbox" name="'+serviceData[i].Name+'" value="'+serviceData[i].Name+'">';
						naturnHTML += '<label for="'+serviceData[i].ID+'" class="button button--blue white-border check-item">'+serviceData[i].Name+'<i class="icon icon-check"></i></label></div>';
						container.append(naturnHTML);	
						checkId.push(serviceData[i].ID);
					}
				}
		}
		return $(wherehtml).html(container);
	}

	function CreateNaturnDetailHTML(wherehtml, setIdBox, parentNature){
		var natureDetailHTML = '';
		if (parentNature.length > 0) {
			for (var i = 0; i < parentNature.length; i++) {
				for (var j = 0; j < natureData.length ; j++) {
					if (parentNature[i] == natureData[j].ParentId) {
						natureDetailHTML += '<div data-boxId="'+setIdBox+'" class="step_detail col-md-6 col-xs-12"><input id="get_'+natureData[j].ID+'" type="checkbox" name="get_'+natureData[j].Name+'" value="'+natureData[j].Name+'">';
						natureDetailHTML += '<label for="get_'+natureData[j].ID+'" class="button button--blue white-border check-item">'+natureData[j].Name+'<i class="icon icon-check"></i></label></div>';
					}
				}
			}
		}else{
			$("#stepNatureDetail").addClass("hidden");
		}
		return $(wherehtml).html(natureDetailHTML);
	}

	function CreateServiceDetailHTML(wherehtml, setIdBox, serviceSelected){
		var serviceDetailHTML = '';
		//console.log(objectServiceParent);
		if (serviceSelected.length > 0) {
			for (var i = 0; i < serviceSelected.length; i++) {
				for (var j = 0; j < objectServiceParent.length ; j++) {
					if (serviceSelected[i] == objectServiceParent[j].ParentId) {
						serviceDetailHTML += '<div data-boxId="'+setIdBox+'" class="step_detail col-md-6 col-xs-12"><input id="get_'+objectServiceParent[j].ID+'" type="checkbox" name="get_'+objectServiceParent[j].Name+'" value="'+objectServiceParent[j].Name+'">';
						serviceDetailHTML += '<label for="get_'+objectServiceParent[j].ID+'" class="button button--blue white-border check-item">'+objectServiceParent[j].Name+'<i class="icon icon-check"></i></label></div>';
					}
				}
			}
		}else{
			//serviceDetailHTML = '<p>NOPE</p>'
			$("#stepServiceDetail").addClass("hidden");
		}
		return $(wherehtml).html(serviceDetailHTML);
	}

	function CreateQuestionHTML(wherehtml, objectQuestions) {
		var container = $('<div class="col-md-6 col-xs-12" />');
		var questionHTML = '<div class="solution-finder__white-box"><div class="title-group"><span class="title">Commingled</span></div>';
		questionHTML += '<div class="custom-dropdown"><select class="custom-dropdown__select"><option selected disabled>Bin Size</option><option selected disabled>Bin Size</option></select></div>';
		questionHTML += '<div class="custom-dropdown"><select class="custom-dropdown__select"><option selected disabled>Collection frequency</option><option>Frequency 1</option></select></div></div>'
		container.append(questionHTML);
		return $(wherehtml).html(container);
	}


	function CreateSummaryHTML(wherehtml, siteBoxData) {
		var container = $('<div class="panel-group" />');
		var numbersSite = siteData.length;
		//get data customer information
	
		var getFirstCus = $(".firstnamecustomer").val();
		var getLastNameCus = $(".lastnamecustomer").val();
		var getPhoneCus = $(".phonecustomer").val();
		var getEmailCus = $(".emailcustomer").val();
		var getCompanyCus = $(".companycustomer").val();
		var getBusinessType = $(".businessType").val();
		if (siteBoxData) {
			for (var i = 0; i < siteBoxData.length; i++) {
				var getIdBox = siteBoxData[i].namesitebox;
				var getNumberSite = getIdBox[getIdBox.length -1];
				console.log("number site" +getNumberSite);
				var hardsite = i + 1;
				if (i == 0) {
					var summaryHTML = '<div class="panel panel-default">';
					summaryHTML += '<div class="panel-heading"><h4 class="panel-title"><a data-toggle="collapse" data-parent="#accordion" href="#site'+i+'">Customer Information</a></h4></div>';
					summaryHTML += '<div id="site'+i+'" class="panel-collapse collapse in"><div class="solution-finder__white-box summary"><div class="col-md-6 col-xs-12 summary__column">';
					summaryHTML += '<span class="summary__label">First Name</span><span class="summary__details">'+getFirstCus+'</span></div><div class="col-md-6 col-xs-12 summary__column">';
					summaryHTML += '<span class="summary__label">Last Name</span><span class="summary__details">'+getLastNameCus+'</span></div><div class="col-md-6 col-xs-12 summary__column">';
					summaryHTML += '<span class="summary__label">Company Name</span><span class="summary__details">'+getCompanyCus+'</span></div><div class="col-md-6 col-xs-12 summary__column">';
					summaryHTML += '<span class="summary__label">Email</span><span class="summary__details">'+getEmailCus+'</span></div><div class="col-md-6 col-xs-12 summary__column">';
					summaryHTML += '<span class="summary__label">Phone</span><span class="summary__details">'+getPhoneCus+'</span></div><div class="col-md-6 col-xs-12 summary__column">';	
					summaryHTML += '<span class="summary__label">Number Of Sites</span><span class="summary__details">'+numbersSite+' Sites</span></div><div class="col-md-6 col-xs-12 summary__column"><span class="summary__label">Business Name</span><span class="summary__details">'+getBusinessType+'</span></div></div></div></div>';
					container.append(summaryHTML);
				}

				var summaryHTML = '<div class="panel panel-default">';
				summaryHTML += '<div class="panel-heading"><h4 class="panel-title"><a data-toggle="collapse" data-parent="#accordion" href="#site'+hardsite+'">Site '+getNumberSite+'</a></h4></div>';
				summaryHTML += '<div id="site'+hardsite+'" class="panel-collapse collapse in"><div class="solution-finder__white-box summary">';
				summaryHTML += '<div class="col-md-6 col-xs-12 summary__column">';
				summaryHTML += '<span class="summary__label">Postcode</span><span class="summary__details">'+ siteBoxData[i].postcode +'</span></div><div class="col-md-6 col-xs-12 summary__column">';
				summaryHTML += '<span class="summary__label">Business size</span><span class="summary__details">'+ siteBoxData[i].businesssize + '</span></div><div class="col-md-6 col-xs-12 summary__column">';
				summaryHTML += '<span class="summary__label">Nature of business</span><span class="summary__details">'+ siteBoxData[i].naturebusiness + '</span></div><div class="col-md-6 col-xs-12 summary__column">';	
				summaryHTML += '<span class="summary__label">Services type</span><span class="summary__details">'+ siteBoxData[i].service + '</span></div></div></div></div>';	
				container.append(summaryHTML);
			}
		}
		
		return $(wherehtml).html(container);
	}


	//Delete function for removing site
	$("body").on("click",".btnDeteleSite",function(e){
		e.stopPropagation();
		var getIdBoxSite = $(this).parent().parent().parent().attr('id');
		$('#confirm-delete-modal').modal()
		$(".deleteSite").attr("data-site",getIdBoxSite);

	});
	$("body").on("click",".deleteSite",function(e){
		e.stopPropagation();
		var idBox = $(this).attr("data-site");
		$("#"+idBox).hide();
		removeArrByString(siteBoxes, idBox);
		$('#confirm-delete-modal').modal('hide');
	});


	//Action for input Site's information
	$("body").on("dblclick",".boxSiteCss",function(e){
		var counter=0;
		var getBox = $(this).attr('id');
		var getPostCode = $(this).find('.postcodeInput').val();
		getStepCu = parseInt($("#btnsub").attr('data-step'), 10);
		if($("#step_infoBusiness").valid()){
			$.ajax({
			    type : "post",
			    dataType : "json",
			    url : urlAjax,
			    data : {
			        action: "random",
			        postcode: getPostCode,
			    },
			    beforeSend: function(){

			    },
			    success: function(response){
			       if (response[0] == "true") {
			       		nextStep();
			       		var getIdPostCode = response[1];
			       		$("#lookingplace").attr('data-boxsite', getBox);
			       		//CreateLookingForHTML("#lookingplace",getBox);
			       		getDataServiceFormCSV("csvservice",getIdPostCode,counterCallAjax);
			       		counterCallAjax++;
			       }else{
			       		//alert("NHAP POSTCODE SAI ROI");
			       		if(counter<=0){
			       			var getSite = "Site " + getBox[getBox.length -1];
				       		var errorPostCode = '<div class="alert alert-danger">Wrong post code: '+getSite+'</div>';
				       		$("#error_alert").html(errorPostCode);
				       		counter++;
			       		}
			       	}
			    },
			    error: function( jqXHR, textStatus, errorThrown){
			        console.log('Thrown Error:' + textStatus, errorThrown);
			    }
			});
		}else{

		}
	});

	//function for creating step 4 - Looking for Page
	$("body").on("click",".btnYesLooking",function(e){
		getStepCu = parseInt($("#btnsub").attr('data-step'), 10);
		var getIdBoxSite = $(this).parent().parent().attr('data-boxsite');
		nextStep();
		$("#natuneplace").attr('data-boxsite', getIdBoxSite);
		getDataNatureFormCSV("csvnature");
		//CreateNaturnServicesHTML("#natuneplace",getIdBoxSite,"natureSelected","Retail");
		//console.log(restttt);
		$("#natuneplace").attr("data-typeidea","Yes");
	});

	$("body").on("click",".btnNoLooking",function(e){
		getStepCu = parseInt($("#btnsub").attr('data-step'), 10);
		var getIdBoxSite = $(this).parent().parent().attr('data-boxsite');
		nextStep();
		$("#natuneplace").attr('data-boxsite', getIdBoxSite);
		getDataNatureFormCSV("csvnature");
		//CreateNaturnServicesHTML("#natuneplace",getIdBoxSite,"natureSelected","Retail");
		//console.log(restttt);
		$("#natuneplace").attr("data-typeidea","No");
	});


	function removeArrByString(arr) {
	    var what, a = arguments, L = a.length, ax;
	    while (L > 1 && arr.length) {
	        what = a[--L];
	        while ((ax= arr.indexOf(what)) !== -1) {
	            arr.splice(ax, 1);
	        }
	    }
	    return arr;
	}

	$("body").on("click",".natureSelected",function(e){
		e.stopPropagation();
		var getValueSelected = $(this).attr('id');
		var getCurrentBox = $("#natuneplace").attr('data-boxsite');
		var getIdService = $(this).parent().attr('data-service');
		var getNameNature = $(this).val();
		if ($(this).is(':checked')) {

			$('.natureSelected').prop('checked', false);
			$(this).prop('checked', true);
			//call function create detail naturn
			$("#stepNatureDetail").addClass("step_5").removeClass("hidden");
			//Multiple choise nature

			//objectNutureParent.push(getValueSelected);
			//natureIdService.push(getIdService);
			//set name for array nature's name
			//getNameNatureString.push(getNameNature);
			objectNutureParent = getValueSelected;
			natureIdService = getIdService;
			getNameNatureString = getNameNature;

			CreateNaturnDetailHTML("#natuneDetailPlace", getCurrentBox, objectNutureParent);
		}else{
			//removeArrByString(objectNutureParent, getValueSelected);
			//removeArrByString(natureIdService, getIdService);
			objectNutureParent = "";
			natureIdService = "";
			getNameNatureString = "";
			//unset name for nature
			removeArrByString(getNameNatureString, getNameNature);
	
			CreateNaturnDetailHTML("#natuneDetailPlace", getCurrentBox, objectNutureParent);
			
		}
	});

	$("body").on("click",".servicesSelected",function(e){
		e.stopPropagation();
		var getValueSelected = $(this).parent().attr('data-service');
		var getNameSelected = $(this).val();
		var getCurrentBox = $("#natuneplace").attr('data-boxsite');
		if ($(this).is(':checked')) {
			$("#stepServiceDetail").addClass("step_6").removeClass("hidden");
			serviceSelect.push(getValueSelected);
			getServiceName.push(getNameSelected);
			CreateServiceDetailHTML("#serviceDetailPlace", getCurrentBox, serviceSelect); 
		}else{
			removeArrByString(serviceSelect, getValueSelected);
			removeArrByString(getServiceName, getNameSelected);
			CreateServiceDetailHTML("#serviceDetailPlace", getCurrentBox, serviceSelect);
		}
	});

	$("body").on("click","#emailSummaryHTML",function(e){
		getDataNatureFormCSV("testcsv");
	});


	function getDataNatureFormCSV(filename){
		var filecsvname = filename;
		$.ajax({
		    type : "post",
		    dataType : "json",
		    url : urlAjax,
		    data : {
		        action: "read_csv_file",
		        csvfilename: filecsvname,
		    },
		    success: function(response){
		    	//add json response to object Nature
		    	$.each( response, function( index, obj ) {
			  		var getParentIdnull = "";
			  		if(obj.ParentId){
			  			getParentIdnull = obj.ParentId;
			  		}else{
			  			getParentIdnull = "0";
			  		}
			  		if (obj.ID !== undefined) {
					    natureData.push({
					        ID: obj.ID,
					        Name: obj.Name,
					        ParentId: getParentIdnull,
					        ServiceId: obj.ServiceId
					    });
			  		}

				});
				//natureData.filter(n => n);
				//console.log(natureData);
				var getIdBoxSite = $("#natuneplace").attr('data-boxsite');
				CreateNaturnServicesHTML("#natuneplace",getIdBoxSite,"natureSelected",natureData);
				addRuleValidationClass();				

		    },
		    error: function( jqXHR, textStatus, errorThrown){
		        console.log('Thrown Error:' + textStatus, errorThrown);
		    }
		});
	}

	function getDataServiceFormCSV(filename, postcodeId, countCallAjax){
		var filecsvname = filename;
		$.ajax({
		    type : "post",
		    dataType : "json",
		    url : urlAjax,
		    data : {
		        action: "read_csv_file",
		        csvfilename: filecsvname,
		    },
		    success: function(response){
		    	$.each( response, function( index, obj ) {
					if (obj.PostcodeId !== undefined) {
						var checkCode = obj.PostcodeId.includes(postcodeId);
						if (checkCode == true) {
							serviceData.push({
						        ID: obj.ID,
						        Name: obj.Name,
						        PostcodeId: obj.PostcodeId
					    	});
						}
					}else{
						if (countCallAjax < 1) {
							objectServiceParent.push({
						        ID: obj.ID,
						        Name: obj.Name,
						        ParentId: obj.ParentId,
					    	});	
						}
					}
		    	});
		    },
		    error: function( jqXHR, textStatus, errorThrown){
		        console.log('Thrown Error:' + textStatus, errorThrown);
		    }
		});
	}


	$("body").on("click","#emailSummaryHTML",function(e){
		e.stopPropagation();
		console.log("OK MAIL");
		nextStep();
	});


	$("body").on("click","#ntnRequestAQoute",function(e){
		$("#submitgravity").click();
		nextStep();
	});




});


