$(window).bind("pageshow", function() {
    if($(".tracksmartIV").find("input[type='submit']").length > 0) {
        var maxTrackSmartRow = 5;
        var rowData = [];

        /* Change dropdown option
         * If options value is 'single update email' or 'multiple to same email' 
         * => display email field, reset all added row's field if have any
         * If options value is 'multiple to different email' 
         * =>display default first row and add first row data to rowData
         */
        $(document).on('change','.updateTaskSiteOption', function(){
            if($(this).find('option:selected').val() != 'multiple to different email'){
                $('.TrackSmartRow:not(".rowTrackSmart1")').addClass('gfield_visibility_hidden');
                $('.rowTrackSmart1').removeClass('gfield_error');
                $('.rowTrackSmart1').find('.validation_message').remove();
                $('.removeRow').addClass('gfield_visibility_hidden');
                if(rowData.length > 1){
                    for (var i = 2; i <= rowData.length; i++) {
                        $('.rowTrackSmart'+i).addClass('gfield_visibility_hidden');
                        $('.rowTrackSmart'+i).find('input[type=text]').val('');
                        $('.rowTrackSmart'+i).find('input[type=email]').val('');
                        $('.rowTrackSmart'+i).removeClass('gfield_error');
                        $('.rowTrackSmart'+i).find('.validation_message').remove();
                    }
                }
                //reset data of multiple to different email when change option
                rowData = [];
                if(!$('.warning-msg-tracksmart').hasClass('hidden')){
                   $('.warning-msg-tracksmart').addClass('hidden') 
                }
                $('.add-row').css('background-color','#0085ca');
            }
            
            //reset field when user change between dropdown options
            if($(this).find('option:selected').val() == 'multiple to different email'){
                $('.updated_email_address').removeClass('gfield_error');
                $('.updated_email_address').find('.validation_message').remove();
                //add first row when select option multiple to different email
                if(rowData.length == 0){
                    rowData.push({
                        'rowClass' : 'rowTrackSmart1',
                        'text' : $('.rowTrackSmart1').find('input[type=text]').val(),
                        'email' : $('.rowTrackSmart1').find('input[type=email]').val(),
                    });
                    $('.totalAddedRow').find('input[type=number]').val(1);
                }
                $('.RemoveRow'+rowData.length).removeClass('gfield_visibility_hidden');
            }
        });

        /* Click button Add Another Update
         * If number of row not reach max limit = 5 
         * => Hide remove icon of current last row before add new one
         * => Display next row
         * If max limit reached => do nothing
         */
        $('body').on("click",".add-row",function(e){
            if(rowData.length < maxTrackSmartRow){
                if(rowData.length > 1 ){
                    $('.RemoveRow'+rowData.length).addClass('gfield_visibility_hidden');
                }
                AddGravityRow();                
            }

        });


        $('body').on("click",".tracksmartIV input[type=submit]",function(e){
            //re-order field data before form submit to make sure it validate from row 1 to 5 after remove/add row sometimes
            if($('.updateTaskSiteOption option:selected').val() === 'multiple to different email'){
                $('.totalAddedRow').find('input[type=number]').val(rowData.length);
            } else {
                $('.totalAddedRow').find('input[type=number]').val(0);
            }            
        });
        
        /* Display new row after click button Add Another Update
         * => Get new row index, display new row
         * => Add row field's value to rowData
         * => Update number of total added row. If reach max limit, set button to grey and display warning message
         */
        function AddGravityRow(){
            var currentRowIndex = rowData.length+1;
            let rowClass = 'rowTrackSmart'+currentRowIndex;
            $('.'+rowClass).removeClass('gfield_visibility_hidden');
            $('.'+rowClass+'.removeRow').removeClass('gfield_visibility_hidden');
            rowData.push({
                'rowClass' : rowClass,
                'text' : $('.'+rowClass).find('input[type=text]').val(),
                'email' : $('.'+rowClass).find('input[type=email]').val(),
            });
            $('.totalAddedRow').find('input[type=number]').val(currentRowIndex);
            if(rowData.length == maxTrackSmartRow){
                $('.add-row').css('background-color','darkgrey');
                $('.warning-msg-tracksmart').removeClass('hidden');
            }
        }


        /* Remove a row from last index
         * => Get current row index, reset field's value
         * => Remove error message if row is removed after validated with error
         * => Hide current row
         * => Display remove icon for previous added row which is being last row after removing
         */
        $('body').on("click",".removeTracksmartRow",function(e){
            const itemClass = $(this).attr('data-div');
            rowIndex = $(this).attr('data-index');

            rowData = $.grep(rowData, function(e){ 
                 return e.rowClass != itemClass; 
            });

            //clear row data and hide row when click on remove icon
            $('.rowTrackSmart'+rowIndex).addClass('gfield_visibility_hidden')
            $('.rowTrackSmart'+rowIndex).removeClass('gfield_visibility_visible');
            $('.rowTrackSmart'+rowIndex).removeClass('gfield_error');
            $('.rowTrackSmart'+rowIndex).find('input').val('');
            $('.rowTrackSmart'+rowIndex).find('.validation_message').remove();
            $('.RemoveRow'+(rowIndex - 1)).removeClass('gfield_visibility_hidden');
            $('.totalAddedRow').find('input[type=number]').val(rowData.length);
            if(rowData.length < maxTrackSmartRow){
                if(!$('.warning-msg-tracksmart').hasClass('hidden')){
                   $('.warning-msg-tracksmart').addClass('hidden') 
                }
                $('.add-row').css('background-color','#0085ca');
            }
        });

        function IsEmail(email) {
            var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if(!regex.test(email)) {
                return false;
            }else{
                return true;
            }
        }
                
        /* This will be run when page reload (goback from thankyou page or submitted form with invalid field's value)
         * => Get total added row
         * => If total added row is equal max limit, display warning massage and disable button Add Another Update
         * => Else if total added row less than max limit, add rows fields's value to rowData
         * => Display added rows with submited value if it has
         */
        if($('.updateTaskSiteOption option:selected').val() === 'multiple to different email' && rowData.length == 0){
            
            const totalAddedRow = parseInt($('.totalAddedRow').find('input[type=number]').val());

            //disable add button if totalAddedRow is max number (5)
            if(totalAddedRow == maxTrackSmartRow){
                $('.add-row').css('background-color','darkgrey');
                $('.warning-msg-tracksmart').removeClass('hidden');
            }
            //reset and hide field validate but removed before submit when goback
            for(var i = totalAddedRow+1; i<=maxTrackSmartRow; i++){
                $('.rowTrackSmart'+i).removeClass('gfield_visibility_visible');
                $('.rowTrackSmart'+i).removeClass('gfield_error');
                $('.rowTrackSmart'+i).find('.validation_message').remove();
                $('.rowTrackSmart'+i).addClass('gfield_visibility_hidden');
            }

            //get retain data when goback to rowData and display field with submitted data
            for(var i=1; i<= totalAddedRow;i++){
                rowData.push({
                    'rowClass' : 'rowTrackSmart'+i,
                    'text' : $('.TaskSideCode'+i).find('input[type=text]').val(),
                    'email' : $('.UpdateTaskEmail'+i).find('input[type=email]').val(),
                });

                $('.rowTrackSmart'+i).removeClass('gfield_visibility_hidden');
                $('.RemoveRow'+i).addClass('gfield_visibility_hidden');                    
                if($('.TaskSideCode'+i).find('input[type=text]').val() != '' && $('.TaskSideCode'+i).hasClass('gfield_error')){
                    $('.TaskSideCode'+i).removeClass('gfield_error');
                    $('.TaskSideCode'+i).find('.validation_message').remove();
                }
                if(IsEmail($('.UpdateTaskEmail'+i).find('input[type=email]').val()) && $('.UpdateTaskEmail'+i).hasClass('gfield_error')){
                    $('.UpdateTaskEmail'+i).removeClass('gfield_error');
                    $('.UpdateTaskEmail'+i).find('.validation_message').remove();
                }
            }
            $('.RemoveRow'+totalAddedRow).removeClass('gfield_visibility_hidden');
            
        } else {
            for(var i=2; i<= maxTrackSmartRow;i++){
                if($('.rowTrackSmart'+i).hasClass('gfield_visibility_visible')){ 
                    $('.rowTrackSmart'+i).addClass('gfield_visibility_hidden');                    
                                  
                }
            }
        }

    }
});

