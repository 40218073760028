//BLUEPRINT 2030 JS ONLY
if ($('.page-template-blueprint2030-page').length > 0) {
    if (window.innerWidth >= 768) {//HERO SECTION
    //var CircleObject = '.blueprint2030-hero__circle-image'; //CIRCLE IMAGE
    //
    //$(CircleObject).on("animationend", function(){ //wait for the css animation to finish
    //    if ($(CircleObject)[0]){
    //        var window_width = $(window).width() - $(CircleObject).width(); //GET WINDOW WIDTH
    //
    //        var document_height = $(document).height() - $(window).height(); //GET WINDOW WIDTH
    //
    //        var LeftPosition = $(CircleObject).offset().left;
    //        var TopPosition = $(CircleObject).offset().top;
    //        console.log(LeftPosition);
    //
    //        $(function () {
    //            $(window).scroll(function () {
    //                var scroll_position = $(window).scrollTop();
    //
    //                //var object_position_left = window_width * (scroll_position / document_height);
    //                var object_position_left = LeftPosition + (scroll_position * 0.8);
    //                var object_position_top = TopPosition + (scroll_position * 1);
    //
    //                if(scroll_position < 400){
    //                    $(CircleObject).css({
    //                        'left': object_position_left,
    //                        'top': object_position_top,
    //                        'position': 'absolute'
    //                    });
    //
    //                } if(scroll_position >= 400){
    //                    if(scroll_position == 400){
    //                   // console.log(object_position_left + ' ' + object_position_top);
    //                   // var leftlastCoordinate = object_position_left;
    //                    //var leftlastCoordinate = object_position_top;
    //                        
    //                    $(CircleObject).css({
    //                        'left': object_position_left,
    //                        'top': object_position_top,
    //                        'position': 'absolute'
    //                    });
    //                }
    //                }
    //            });
    //        });
    //    }
    //    
    //});
    $(function() {
        window.onorientationchange = function() { 
            var orientation = window.orientation; 
                switch(orientation) { 
                    case 0:
                    case 90:
                    case -90: window.location.reload(); 
                    break; } 
        };

        ScrollTrigger.matchMedia({
            "(min-width: 767px)": function() {

                //hero js
                $(function() {
                    let tl_hero = gsap.timeline({
                        scrollTrigger: {
                            trigger: ".blueprint2030-hero",
                            start: "top top",
                           // end: "bottom bottom",
                            //scrub: true
                            toggleActions: "none pause pause reset"
                        }

                        })

                    tl_hero.addLabel("start")
                      .to(".blueprint2030-hero__circle-image", {left: getCurrentleft, top: getCurrenttop})
                      //.to(".blueprint2030-hero__circle-image", {left: getleft, top: gettop, duration: 1})
                      .addLabel("end");

                    gsap.fromTo(".blueprint2030-hero__circle-image img", {rotation: 0}, {rotation: 360, duration: 40, repeat: -1, ease: "linear"})

                //    gsap.to(".blueprint2030-hero__circle-image", {
                //      rotation: 140, 
                //      duration: 60, 
                //      ease: "none", 
                //      repeat: -1 
                //    }).totalProgress(0.5);



                    function getCurrenttop() {
                      return $('.from-div').offset().top;
                    }

                    function getCurrentleft() {
                      return $('.from-div').offset().left;
                    }

                });

                //PILLARS JS
                //$(function () {
                //    $(window).scroll(function () {
                //      var scroll_position = $(window).scrollTop();
                //     
                //        if($(window).scrollTop() >= $(".blueprint2030-pillars__content--blue").offset().top + $(".blueprint2030-pillars__content--blue").outerHeight() - window.innerHeight + 100) {
                //            $( ".blueprint2030-pillars__content--blue" ).addClass( "height-1200" );
                //            $( ".blueprint2030-pillars__content--green" ).addClass( "height-1200" );
                //        }
                //        if($(window).scrollTop() >= $(".blueprint2030-pillars__content--green").offset().top + $(".blueprint2030-pillars__content--green").outerHeight() - window.innerHeight + 150) {
                //            $( ".blueprint2030-pillars__content--green" ).addClass( "height-1800" );
                //        }
                //    });
                //});

                //first section circle
                $(function() {
                let tl1 = gsap.timeline({
                    scrollTrigger: {
                      trigger: ".blueprint2030-firstsection",
                      start: "center bottom-=100px", // when the top of the trigger hits the top of the viewport
                      toggleActions: "play pause pause reset"
                    }
                  });

                 tl1.addLabel("start")
                      .to(".blueprint2030-hero__circle-image", {left: getleft, top: gettop})
                      .addLabel("end");

                    function gettop() {
                      return $('.target-div').offset().top;
                    }

                    function getleft() {
                      return $('.target-div').offset().left;
                    }
                })

                //first section
                $(function() {
                let tl1 = gsap.timeline({
                    scrollTrigger: {
                      trigger: ".blueprint2030-firstsection__text",
                      start: "center bottom-=100px", // when the top of the trigger hits the top of the viewport
                      once: true
                    }
                  });

                 tl1.addLabel("start")
                      .from(".blueprint2030-firstsection__text", {translateX: -200, opacity: 0})
                      .addLabel("end");

                })

                //second section
                $(function() {
                let tl1 = gsap.timeline({
                    scrollTrigger: {
                      trigger: ".blueprint2030-secondsection__wrapper",
                      start: "center bottom-=100px", // when the top of the trigger hits the top of the viewport
                      once: true
                    }
                  });

                 tl1.addLabel("start")
                      .from(".blueprint2030-secondsection__wrapper .box", {translateY: -200, opacity: 0})
                      .from(".blueprint2030-secondsection__text-bar", {translateY: -100, opacity: 0})
                      .addLabel("end");
                })

                //pillar scrolltrigger
                $(function() {
                let intro_bar = gsap.timeline({
                    scrollTrigger: {
                      trigger: ".blueprint2030-pillars .blueprint2030-pillars__text-bar",
                      start: "top center", // when the top of the trigger hits the top of the viewport
                      once: true
                      //onEnter: self => $('.blueprint2030-pillars__content--green').addClass('height-1200'),
                    }
                  });

                    intro_bar.addLabel("start")
                      .from(".blueprint2030-pillars .blueprint2030-pillars__text-bar", {translateY: -200, opacity: 0})
                      .addLabel("end");

                let outro_bar = gsap.timeline({
                    scrollTrigger: {
                      trigger: ".blueprint2030-pillars-outro",
                      start: "top center", // when the top of the trigger hits the top of the viewport
                      once: true,
                      toggleClass: "active"
                      //onEnter: self => $('.blueprint2030-pillars__content--green').addClass('height-1200'),
                    }
                  });

                    outro_bar.addLabel("start")
                      .from(".blueprint2030-pillars-outro .blueprint2030-pillars-outro__text-bar", {translateY: -200, opacity: 0})
                      .from(".blueprint2030-proposition .blueprint2030-proposition__text-bar", {translateY: -200, opacity: 0, delay: 0.4})
                      .addLabel("end");

                //let second_outro_bar = gsap.timeline({
                //    scrollTrigger: {
                //      trigger: ".blueprint2030-pillars-outro .blueprint2030-pillars-outro__text-bar:last-child",
                //      start: "top-=250px top", // when the top of the trigger hits the top of the viewport
                //      once: true
                //      //onEnter: self => $('.blueprint2030-pillars__content--green').addClass('height-1200'),
                //    }
                //  });
                //    
                //    second_outro_bar.addLabel("start")
                //      .from(".blueprint2030-pillars-outro .blueprint2030-pillars-outro__text-bar:last-child", {translateY: -200, opacity: 0})
                //      .addLabel("end");

                let tl_blue = gsap.timeline({
                    scrollTrigger: {
                      trigger: ".blueprint2030-pillars__content--blue",
                      start: "top center", // when the top of the trigger hits the top of the viewport
                      once: true,
                      onUpdate: blueEnter
                      //onEnter: self => $('.blueprint2030-pillars__content--green').addClass('height-1200'),
                    }
                  });

                 tl_blue.addLabel("start")
                      .from(".blueprint2030-pillars__top img", {translateY: -200, opacity: 0}, "<") // < means insert at the START of the  previous animation
                      .from(".blueprint2030-pillars__content--blue", {height: 200, onComplete: completeBlue}, "<") // < means insert at the START of the  previous animation
                      .from(".blueprint2030-pillars__content--orange", {height: 200}, "<") // < means insert at the START of the  previous animation
                      .from(".blueprint2030-pillars__content--green", {height: 200}, "<") // < means insert at the START of the  previous animation
                      .from(".blueprint2030-pillars__content--blue .blueprint2030-pillars__content-first", {opacity: 0}, "<") // < means insert at the START of the  previous animation
                      .from(".blueprint2030-pillars__content--orange .blueprint2030-pillars__content-first", {opacity: 0}, "<") // < means insert at the START of the  previous animation
                      .from(".blueprint2030-pillars__content--green .blueprint2030-pillars__content-first", {opacity: 0}, "<") // < means insert at the START of the  previous animation
                      .from(".blueprint2030-pillars__content--blue .blueprint2030-pillars__content-second", {opacity: 0}, "<") // < means insert at the START of the  previous animation
                      .addLabel("end");

                    function blueEnter() {
                       ScrollTrigger.refresh();
                       //console.log('orange enter');
                    }

                    function completeBlue() {
                      var winHeight = $(window).height(),
                        topOffset = $(".blueprint2030-pillars__content--blue").offset().top,
                        elementHeight = $('.blueprint2030-pillars__content--blue').height()
                      var top = topOffset - winHeight + elementHeight;

                      $('html, body').animate({
                            scrollTop: top + 200
                        }, 200);
                    }

                let tl_orange = gsap.timeline({
                    scrollTrigger: {
                      trigger: ".blueprint2030-pillars__content--orange",
                      start: "top+=150px top", // when the top of the trigger hits the top of the viewport
                      once: true,
                      onUpdate: orangeEnter
                      //onEnter: self => $('.blueprint2030-pillars__content--green').addClass('height-1600'),
                    }
                  });

                  tl_orange.addLabel("start")
                      .to(".blueprint2030-pillars__content--orange", {height: 990, onComplete: completeOrange}, "<") // < means insert at the START of the  previous animation
                      .to(".blueprint2030-pillars__content--green", {height: 990}, "<")
                      .from(".blueprint2030-pillars__content--orange .blueprint2030-pillars__content-second", {opacity: 0}, "<") // < means insert at the START of the  previous animation
                      .addLabel("end");

                    function orangeEnter() {
                       ScrollTrigger.refresh();
                       //console.log('blue enter');
                    }

                    function completeOrange() {
                      var winHeight = $(window).height(),
                        topOffset = $(".blueprint2030-pillars__content--orange").offset().top,
                        elementHeight = $('.blueprint2030-pillars__content--orange').height()
                      var top = topOffset - winHeight + elementHeight;

                      $('html, body').animate({
                            scrollTop: top + 200
                        }, 200);
                    }

                let tl_green= gsap.timeline({
                    scrollTrigger: {
                      trigger: ".blueprint2030-pillars__content--green",
                      start: "top+=450px top", // when the top of the trigger hits the top of the viewport
                      once: true,
                      onUpdate: greenEnter
                    }
                  });

                  tl_green.addLabel("start")
                      .to(".blueprint2030-pillars__content--green", {height: 1330, onComplete: completeGreen})
                      .from(".blueprint2030-pillars__content--green .blueprint2030-pillars__content-second", {opacity: 0}, "<") // < means insert at the START of the  previous animation
                      .addLabel("end");

                    function greenEnter() {
                       ScrollTrigger.refresh();
                       //console.log('green enter');
                    }

                    function completeGreen() {
                      var winHeight = $(window).height(),
                      topOffset = $(".blueprint2030-pillars__content--green").offset().top,
                      elementHeight = $('.blueprint2030-pillars__content--green').height()
                      var top = topOffset - winHeight + elementHeight;

                      $('html, body').animate({
                            scrollTop: top + 200
                        }, 200);
                    }

                });

                //proposition scrolltrigger
                $(function() {
                let tl = gsap.timeline({
                    scrollTrigger: {
                      trigger: ".blueprint2030-proposition svg",
                      start: "center-=100px bottom", // when the top of the trigger hits the top of the viewport
                      //scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                      once: true,
                      toggleClass: "active"
                    }
                  });

                    tl.addLabel("start")
                      .from(".blueprint2030-proposition svg #Group_59.animate-left", {xPercent: -50, opacity: 0})
                      .from(".blueprint2030-proposition svg #Group_58.animate-right", {xPercent: 50, opacity: 0})
                      .from(".blueprint2030-proposition svg #Group_60.animate-top", {yPercent: -50, opacity: 0})
                      .from(".blueprint2030-proposition svg text.animate-left", {xPercent: 150, opacity: 0})
                      .from(".blueprint2030-proposition svg text.animate-right", {xPercent: 450, opacity: 0})
                      .from(".blueprint2030-proposition svg text.animate-bottom", {yPercent: 450, opacity: 0})
                      .from(".blueprint2030-proposition svg #arrowhead", {yPercent: -50, opacity: 0})
                      .addLabel("end");
                });
            }
        }); 
    })
    //BASIC INTERSECTOBSERVER
    //function scrollTrigger(selector, options = {}){
    //    let els = document.querySelectorAll(selector)
    //    console.log(els);
    //    els = Array.from(els)
    //    els.forEach(el => {
    //        addObserver(el, options)
    //    })
    //}
    //
    //function addObserver(el, options){
    //    if(!('IntersectionObserver' in window)){
    //        if(options.cb){
    //            options.cb(el)
    //        }else{
    //            entry.target.classList.add('active')
    //        }
    //        return
    //    }
    //    let observer = new IntersectionObserver((entries, observer) => { //this takes a callback function which receives two arguments: the elemts list and the observer instance
    //        entries.forEach(entry => {
    //            if(entry.isIntersecting){
    //                if(options.cb){
    //                    options.cb(el)
    //                }else{
    //                    entry.target.classList.add('active')
    //                }
    //                observer.unobserve(entry.target)
    //            }
    //        })
    //    }, options)
    //    observer.observe(el)
    //}
    // Example usages:
    //scrollTrigger('.blueprint2030-firstsection__text', {
    //    rootMargin: '-400px',
    //})
    //scrollTrigger('.blueprint2030-secondsection__wrapper img', {
    //    rootMargin: '-400px',
    //})
    //scrollTrigger('.blueprint2030-proposition__wrapper img', {
    //    rootMargin: '-200px',
    //})
    //scrollTrigger('svg', {
    //    rootMargin: '-400px',
    //})
    }
}