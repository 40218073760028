// ONLY FOR RECYCLING HUB PAGE
$(document).ready(function(){
    //Multiple slider on homepage
    $(".multiple-slider").slick({
        infinite: true,
        arrows: false,
        slidesToShow: 4, 
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true
                
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
    
    

    $('.tricky-container__prev').click(function(){
        $(".multiple-slider").slick('slickPrev');
    });

    $('.tricky-container__next').click(function(){
        $(".multiple-slider").slick('slickNext');
    });
    
    //Directory slider on directory page
    $(".directory-slider").slick({
        autoplay: true,
        infinite: true,
        arrows: false,
        dots: true,
        slidesToShow: 1, 
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 992,
              settings: {
                dots: false
              }
            }
        ]
    });


    $('.directory-slider__prev').click(function(){
        $(".directory-slider").slick('slickPrev');
    });

    $('.directory-slider__next').click(function(){
        $(".directory-slider").slick('slickNext');
    });

    //Recycling resources on recycling hub
    // $(".videolist").slick({
    //     infinite: true,
    //     arrows: false,
    //     dots: false,
    //     slidesToShow: 4, 
    // });

    if ($('.recycling_type_video').length) {
         //Recycling resources on recycling hub
        $('.videolist-area-filler').addClass('hidden');
        $('.video-gallery').fadeIn(1000);
        $(".videolist").slick({
            infinite: true,
            arrows: false,
            dots: false,
            slidesToShow: 4, 
        });
    }

    
    $('.recycling-resource-tabs a').on('show.bs.tab', function(e){
        let dropdownLabel = 'Downloadables';
        const openedTabId = $(e.target).attr('href');
        if (openedTabId === '#tab-video') {
            dropdownLabel = 'Videos';
            setTimeout(function() {
                $(".videolist")
                .on('init', function() {
                    $('.videolist-area-filler').addClass('hidden');
                    $('.video-gallery').fadeIn(1000);
                })
                .slick({
                    infinite: true,
                    arrows: false,
                    dots: false,
                    slidesToShow: 4, 
                });
              // .slick('refresh');
            }, 500);
        }

        $('#tab-dropdown-foreground-label').empty().append(dropdownLabel+'<span class="iconcaret icon icon-arrow"></span>');
    });

    $('.recyclinghub-dropdownbtn-resources').click(function(){
        const elemId = this.id;
        let which = 'first';
        if (elemId === 'tab-video-drop') {
            which= 'last';
        }
        $('.recycling-resource-tabs > li').removeClass('active');
        $( '.recycling-resource-tabs li:' + which ).addClass('active');
    });


    $('.videolist__prev').click(function(){
        $(".videolist").slick('slickPrev');
    });

    $('.videolist__next').click(function(){
        $(".videolist").slick('slickNext');
    });
    
    function toggleIcon(e) {
        $(e.target)
            .prev('.panel-heading')
            .find(".more-less")
            .toggleClass('icon-plus icon-minus');
    }
    $('.panel-group').on('hidden.bs.collapse', toggleIcon);
    $('.panel-group').on('shown.bs.collapse', toggleIcon);


    //Stop auto slide when device is mobile
    var breakpoint = 993;
    $('#recySlider').carousel({ interval: (window.innerWidth<breakpoint)?false:5000 });
    $(window).on('resize', function(ev){
      if( window.innerWidth < breakpoint ){ 
        $('#recySlider').carousel({ interval: false });
      } else {
        $('#recySlider').carousel({ interval: 5000 });
      }
    })

    function attachedMobileDropdownEvent(subject, clearClassTo, appendTo) {
        $(subject).click( function() {  
            $(clearClassTo).removeClass("active");
            $(this).addClass("active");
            var get_icon = $(this).find("span").prop('outerHTML');
            var get_tab_text = (get_icon || '') + $(this).text() + '<span class="iconcaret icon icon-arrow"></span>' ;
            $(appendTo).html(get_tab_text);
        });
    }

    attachedMobileDropdownEvent(".recycling-hub-after .mobile-filter .recyclinghub-dropdownbtn", ".recyclinghub-dropdownbtn", ".recyclinghub-dropbtn-hub-after");
    attachedMobileDropdownEvent(".recycle-resources .mobile-filter .recyclinghub-dropdownbtn-resources", ".recyclinghub-dropdownbtn-resources", "#tab-dropdown-foreground-label");

})

if ($('.page-template-SFH-template').length > 0) {
    /* Animated banner */
    $(document).ready(function(){
        $( "#itm1" ).hover(function() {
             $("#itm1").removeClass("floating");
        });
    });
}

/*Recycling Hub Ovelay*/
function showOverlayCommingled(itemID) {
    $("#recyclinghub-overlay-commingled").addClass('open');
    $("#" + itemID).addClass('active');
    $("body").css('overflow','hidden');
}

function hideOverlayCommingled() {
    $("#recyclinghub-overlay-commingled").removeClass('open');
    $(".recycling-popup-commingled").removeClass('active');
    $("body").css('overflow','auto');
}

function showOverlayGeneral(itemID) {
    $("#recyclinghub-overlay-general").addClass('open');
    $("#" + itemID).addClass('active');
    $("body").css('overflow','hidden');
}

function hideOverlayGeneral() {
    $("#recyclinghub-overlay-general").removeClass('open');
    $(".recycling-popup-general").removeClass('active');
    $("body").css('overflow','auto');
}

function showOverlayGeneral3(itemID) {
    var checkIfExist = $("#" + itemID).find('.list-items .list-items__container').length;
    if(checkIfExist !=0){
        $("#recyclinghub-overlay-general3").addClass('open');
        $("#" + itemID).addClass('active');
        $("body").css('overflow','hidden');
    }
    //var checkIfExist = $("#" + itemID).find('.list-items .list-items__container').length;
    //if(checkIfExist !=0){
        $("#recyclinghub-overlay-general3").addClass('open');
        $("#" + itemID).addClass('active');
        $("body").css('overflow','hidden');
    //}
}

function hideOverlayGeneral3() {
    $("#recyclinghub-overlay-general3").removeClass('open');
    $(".recycling-popup-general3").removeClass('active');
    $("body").css('overflow','auto');
}

function prevOverlayCommingled() {
    var last_element = $(".recycling-popup-commingled").last();
    var activeID = $(".recycling-popup-commingled.active").attr("id");
    var checkPrev = $("#" +activeID).prev('.recycling-popup-commingled').length;

    if (checkPrev != 0){
        $("#" +activeID).prev().addClass('active');
        $("#" +activeID).removeClass('active');
    }else{
        last_element.addClass('active');
        $("#" +activeID).removeClass('active');
    }
}

function nextOverlayCommingled() {
    var first_element = $(".recycling-popup-commingled").first();
    var activeID = $(".recycling-popup-commingled.active").attr("id");
    var checkNext = $("#" +activeID).next('.recycling-popup-commingled').length;

    if (checkNext != 0){
        $("#" +activeID).next().addClass('active');
        $("#" +activeID).removeClass('active');
    }else{
        first_element.addClass('active');
        $("#" +activeID).removeClass('active');
    }
}

function prevOverlayGeneral() {
    var last_element = $(".recycling-popup-general").last();
    var activeID = $(".recycling-popup-general.active").attr("id");
    var checkPrev = $("#" +activeID).prev('.recycling-popup-general').length;
    if (checkPrev != 0){
        $("#" +activeID).prev().addClass('active');
        $("#" +activeID).removeClass('active');
    }else{
        last_element.addClass('active');
        $("#" +activeID).removeClass('active');
    }
   
}

function nextOverlayGeneral() {
    var first_element = $(".recycling-popup-general").first();
    var activeID = $(".recycling-popup-general.active").attr("id");
    var checkNext = $("#" +activeID).next('.recycling-popup-general').length;

    if (checkNext != 0){
        $("#" +activeID).next().addClass('active');
        $("#" +activeID).removeClass('active');
    }else{
        first_element.addClass('active');
        $("#" +activeID).removeClass('active');
    }
}

function prevOverlayGeneral3() {
    var last_element = $(".recycling-popup-general3").last();
    var activeID = $(".recycling-popup-general3.active").attr("id");
    var checkPrev = $("#" +activeID).prev('.recycling-popup-general3').length;
    //var checkPrev = $("#" +activeID).prev('.recycling-popup-general3').find('.list-items .list-items__container').length;
    if (checkPrev != 0){
        $("#" +activeID).prev().addClass('active');
        $("#" +activeID).removeClass('active');
    }else{
        last_element.addClass('active');
        $("#" +activeID).removeClass('active');
    }
   
}

function nextOverlayGeneral3() {
    var first_element = $(".recycling-popup-general3").first();
    var activeID = $(".recycling-popup-general3.active").attr("id");
    var checkNext = $("#" +activeID).next('.recycling-popup-general3').length;

    if (checkNext != 0){
        $("#" +activeID).next().addClass('active');
        $("#" +activeID).removeClass('active');
    }else{
        first_element.addClass('active');
        var first_activeID = first_element.attr("id");
        if(first_activeID != activeID){
            $("#" +activeID).removeClass('active');
        }
    }
}

/*Re-write function - HN*/
function showOverlayRepeat(overlayMain, itemID) {
    $("#" + overlayMain).addClass('open');
    $("#" + itemID).addClass('active');
    $("body").css('overflow','hidden');
}

function hideOverlayRepeat(overlayMain, itemID) {
    $("#" + overlayMain).removeClass('open');
    $("#" + overlayMain).find('.active').removeClass('active');
    $("body").css('overflow','auto');
}

function nextOverlayRepeat(overlayMain, itemClass) {
    var first_element = $("." + itemClass).first();
    var activeID = $("#" + overlayMain).find('.active').attr("id");
    var checkNext = $("#" +activeID).next('.' +itemClass).length;
    console.log(checkNext);
    console.log(first_element);
    //var checkNext = $("#" +activeID).next('.recycling-popup-general3').find('.list-items .list-items__container').length;

    if (checkNext != 0){
        $("#" +activeID).next().addClass('active');
        $("#" +activeID).removeClass('active');
    }else{
        first_element.addClass('active');
        $("#" +activeID).removeClass('active');
    }
}

function prevOverlayRepeat(overlayMain, itemClass) {
    var last_element = $("." + itemClass).last();
    var activeID = $("#" + overlayMain).find('.active').attr("id");
    var checkPrev = $("#" +activeID).prev('.' +itemClass).length;
    if (checkPrev != 0){
        $("#" +activeID).prev().addClass('active');
        $("#" +activeID).removeClass('active');
    }else{
        last_element.addClass('active');
        $("#" +activeID).removeClass('active');
    }
   
}

/*Wait for big bin image to be loaded*/
$(".big-bin-loading").one("load", function() {
  $(this).removeClass("big-bin-loading");
    $('.item').removeClass("item-loading");
}).each(function() {
  if(this.complete) {
      //$(this).load(); // For jQuery < 3.0 
      $(this).trigger("load"); // For jQuery >= 3.0 
  }
});


//Mobile tabs menu off canvas
$('#tabs-menu').click(function(){
    $('#menu-container').addClass('active');
});

$('#menu-close').click(function(){
    $('#menu-container').removeClass('active');
});

// Reset List item open when slider is not active
$('#recySlider').on('slide.bs.carousel', function (event) {
  var item = event.relatedTarget;
  $(item).find("div[id^='mobile-list']").removeClass('in').addClass('collapse');
})

$('#select-dropdown').click(function(){
    $('#a-z-grid').toggleClass('active');
    $('#select-dropdown .arrow-down').toggleClass('active');
});

/*video hub page filter dropdown*/
$(".drop-menu").click(function(){
    $('.dropdown-content').toggle('slow');    
});

if ($('.page-template-recycling-hub-page').length > 0) {
    $(document).ready(function(){
    //MENU Function - Show/Hide Page when user clicking on Menu
    $(".page-template-recycling-hub-page .recycling-menu ul li, .menu-items div, .mobile-recycling-menu div").not('.except-event').click(function(){
        var id = $(this).attr("id");
        var current_url = $(".current_url").val();
        $(".recycling-menu ul li, .mobile-recycling-menu div, .menu-items div").removeClass("active");
        $('.recycling-menu ul li[id='+id+'], .mobile-recycling-menu div[id='+id+'], .menu-items div[id='+id+']').addClass("active");
        $('#menu-container').removeClass('active');

        $(".recycling_slider").slick('refresh');
        $(".recycling-wrapper").removeClass("recycling-wrapper-visible");
        $(".recycling-hub-" + id).addClass("recycling-wrapper-visible");

        //refresh search input
        $(".confirm-delete").addClass("hidden");
        $(".form-group__search").val('');
        $("[class*=block-letter-]").removeClass("hidden");

        $('.dropdown-content').hide();

        window.history.pushState("", "", current_url + "?tab=" + id);

        window.setTimeout(function() {
            $(window).scrollTop(0); 
        }, 0);
     
    });

    })


    //A-Z Filter - Show/Hide Row when user select letter 
    $(".page-template-recycling-hub-page .recycling-hub__AtoZ-grid-table-cell").click(function(){
        var key_letter = $(this).text().trim();

        $(this).parent().parent().parent().parent().removeClass("active");
        $(".select-a-z__input").find("input").val(key_letter);

        $("[class*=block-letter-]").addClass("hidden");
        
        if ($(".block-letter-" + key_letter.trim()).length > 0) {
            $(".recycling-hub__no-results").addClass("hidden");
            $(".block-letter-" + key_letter.trim()).removeClass("hidden");
        }else{
            $(".recycling-hub__no-results").removeClass("hidden");
        }
    });

    //Function suggestion
    $(".page-template-recycling-hub-page .form-group__search").on("keyup", function(event) {

        $('.carousel').carousel('pause');
        if (event.keyCode !== 13) {
            $(".recycling-hub__no-results").addClass("hidden");
            $(".down-indicator").css("display", "none");
            $(".autocomplete-items").css("display", "block");
            var value = $(this).val().toLowerCase();
            $(".autocomplete-items input").filter(function() {
              $(this).parent().toggle($(this).val().toLowerCase().indexOf(value) > -1)
            });

            if( !$(this).val() ) {
                $("[class*=block-letter-]").removeClass("hidden");
                $(".autocomplete-items").css("display", "none");

                $(".confirm-delete").addClass("hidden");
            }else{
                $(".confirm-delete").removeClass("hidden");
            }
        }
      });

    //When user select a recycling hub
    $(".page-template-recycling-hub-page .recycling_row").click(function(e) {
        $(".recycling-hub__no-results").addClass("hidden");

        $(".recycling_slider").slick('refresh');

        $(".recycling-wrapper").removeClass("recycling-wrapper-visible");
        $(".recycling-hub-directory").addClass("recycling-wrapper-visible");

        $(".recycling-menu ul li").removeClass("active");
        $('.recycling-menu ul li[id="directory"]').addClass("active");

        $(".autocomplete-items").css("display", "none");

        var recycling_id = $(this).attr("data-recyclingId");

        $("[class*=block-letter-]").addClass("hidden");

        $(".recycling-id-" + recycling_id).removeClass("hidden");

        //$(".autocomplete-items").css("display", "none");

        $('html, body').animate({
            scrollTop: $("#second-section_position").offset().top - 100
        }, 1000);

        var get_text_search = $(this).find("input").val();

        $(".form-group__search").val(get_text_search);

    });

    //remove input text
    $(".page-template-recycling-hub-page .confirm-delete").click(function(){
          $(".down-indicator").css("display", "block");
        $(".recycling-hub__no-results").addClass("hidden");
        $(".confirm-delete").addClass("hidden");
        $(".form-group__search").val('');
        $("[class*=block-letter-]").removeClass("hidden");
        $(".autocomplete-items").css("display", "none");
    });

    //redirect to directory page
    $(".page-template-recycling-hub-page .directory_active").click(function(){
        $("html, body").animate({scrollTop: 0}, 0);
        $(".recycling_slider").slick('refresh');


        $(".recycling-wrapper").removeClass("recycling-wrapper-visible");
        $(".recycling-hub-directory").addClass("recycling-wrapper-visible");

        $(".recycling-menu ul li").removeClass("active");
        $('.recycling-menu ul li[id="directory"]').addClass("active");

    });

    $(".page-template-recycling-hub-page .recycling-hub__search-bar-button").click(function(){
    	var get_text_search = $(".form-group__search-page2").val();
    	if (!get_text_search) {
            get_text_search = $(".form-group__search_slider1").val();
            if(!get_text_search){
                get_text_search = $(".form-group__search_slider2").val();
            }
        }

    	$(".recycling_slider").slick('refresh');

    	$(".recycling-wrapper").removeClass("recycling-wrapper-visible");
        $(".recycling-hub-directory").addClass("recycling-wrapper-visible");
    	   
        $(".recycling-menu ul li").removeClass("active");
        $('.recycling-menu ul li[id="directory"]').addClass("active");

        $(".autocomplete-items").css("display", "none");

    	$('html, body').animate({
            scrollTop: $("#second-section_position").offset().top - 100
        }, 1000);

        if (get_text_search) {

        	var get_element = $(".one-recycling-hub .recycling-hub-name").filter(function() {
    	      	return $(this).text().trim().toLowerCase().indexOf(get_text_search) > -1
    	    });
        	

        	if (get_element.length > 0) {
        		$(".recycling-hub__no-results").addClass("hidden");
        		$(".autocomplete-items").css("display", "none");
        		$("[class*=block-letter-]").addClass("hidden");
        		get_element.parent().parent().parent().removeClass("hidden");
        	}else{
        		$("[class*=block-letter-]").addClass("hidden");
        		$(".recycling-hub__no-results").removeClass("hidden");
        	}
        }
    });

    $( ".page-template-recycling-hub-page .form-group__search-page2, .form-group__search_slider1, .form-group__search_slider2" ).keypress(function() {
      var keycode = (event.keyCode ? event.keyCode : event.which);
        if(keycode == '13'){
            var get_text_search = $(this).val();

            $(".autocomplete-items").css("display", "none");

            $(".recycling_slider").slick('refresh');

            $(".recycling-wrapper").removeClass("recycling-wrapper-visible");
            $(".recycling-hub-directory").addClass("recycling-wrapper-visible");

            $(".recycling-menu ul li").removeClass("active");
            $('.recycling-menu ul li[id="directory"]').addClass("active");

            $(".autocomplete-items").css("display", "none");
            
            $('html, body').animate({
                scrollTop: $("#second-section_position").offset().top - 100
            }, 1000);

            if (get_text_search) {
        
                var get_element = $(".one-recycling-hub .recycling-hub-name").filter(function() {
                    return $(this).text().trim().toLowerCase().indexOf(get_text_search) > -1
                });
                

                if (get_element.length > 0) {
                    $(".recycling-hub__no-results").addClass("hidden");
                    $(".autocomplete-items").css("display", "none");
                    $("[class*=block-letter-]").addClass("hidden");
                    get_element.parent().parent().parent().removeClass("hidden");
                }else{
                    $("[class*=block-letter-]").addClass("hidden");
                    $(".recycling-hub__no-results").removeClass("hidden");
                }
            }
        }
    });


    $(".page-template-recycling-hub-page .view-plastic-btn").click(function(){

        var reycling_id = $(this).attr('data-recycling-type');

        $(".icon-close2").click();

        $(".recycling_slider").slick('refresh');

        $(".recycling-wrapper").removeClass("recycling-wrapper-visible");
        $(".recycling-hub-directory").addClass("recycling-wrapper-visible");

        $(".recycling-menu ul li").removeClass("active");
        $('.recycling-menu ul li[id="directory"]').addClass("active");

        $('html, body').animate({
            scrollTop: $("#second-section_position").offset().top - 100
        }, 1000);

        if (reycling_id) {
        
            var get_element = $(".one-recycling-hub").filter(function() {
                return $(this).attr('data-recycling-type').trim().toLowerCase().indexOf(reycling_id) > -1
            });
            

            if (get_element.length > 0) {
                $(".recycling-hub__no-results").addClass("hidden");
                $(".autocomplete-items").css("display", "none");
                $("[class*=block-letter-]").addClass("hidden");
                get_element.removeClass("hidden");
            }else{
                $("[class*=block-letter-]").addClass("hidden");
                $(".recycling-hub__no-results").removeClass("hidden");
            }
        }
    });


    $(".page-template-recycling-hub-page .how-plastic-btn").click(function(){

        $(".icon-close2").click();
        $("html, body").animate({scrollTop: 0}, 0);
        $(".recycling_slider").slick('refresh');

        $(".recycling-wrapper").removeClass("recycling-wrapper-visible");
        $(".recycling-hub-after").addClass("recycling-wrapper-visible");

        $(".recycling-menu ul li").removeClass("active");
        $('.recycling-menu ul li[id="after"]').addClass("active");

    });



    $(document).mouseup(function(e) 
    {
        var container = $(".form-group__search-page2, .form-group__search_slider1, .form-group__search_slider2");
        if (!container.is(e.target) && container.has(e.target).length === 0) 
        {
            $(".autocomplete-items").css("display", "none");
        }
    });


    $(window).scroll(function() {
        var top_of_sfh_banner = $("#hero-section").offset().top;
        var bottom_of_sfh_banner = $("#hero-section").offset().top + $("#hero-section").outerHeight();

        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight() - 300;
        var top_of_screen = $(window).scrollTop() + $(window).innerHeight() - 300;

         if ((bottom_of_screen > top_of_sfh_banner) && (top_of_screen < bottom_of_sfh_banner)){
            $('.carousel').carousel();

        } else{
            $('.carousel').carousel('pause');
        }

    });
}

//recycling form page

$(".page-template-recycling-form-page .recycling-menu ul li, .menu-items div").click(function(){

    $(".page-template-recycling-form-page .recycling-menu ul li, .mobile-recycling-menu div, .menu-items div").removeClass("active");
    $(this).addClass("active");
    $('#menu-container').removeClass('active');
});


//Video tabs active video
$(".video-item").not('.video-item-mobile').click(function(){
    const me = $(this);
    const youtubeEmbedLink = me.attr('data-yt-link');
    const description = me.attr('data-description');
    const title = me.find('.video-item__title').html();
    
    // const activeVideoID = this.id;
    $(this).addClass('active').siblings().removeClass('active');
    // showVideo(activeVideoID);

    setActiveYoutube(title, description, youtubeEmbedLink);
});

function setActiveYoutube(title, description, ytLink) {
    const video_player = $('.video');
    video_player.find('.video__title').text(title);
    video_player.find('.video__desc').text(description);
    video_player.find('iframe').attr('src', ytLink);
}


//Show video on the bottom when the active video is clicked
function showVideo(activeVideoID) {
    $('.video').removeClass('active');
    $('.'+activeVideoID).addClass('active');
}


$(function(){
    $('iframe[src*="//www.youtube.com/embed/"]').each(function(i) {
      this.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    });
});