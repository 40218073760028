( function( $ ) {
	$(document).ready(function(){
		$("#sync_loading_spinner").hide();		
		$('#cwy-sync-btn').click(function(e) {
			$("#sync_loading_spinner").show();									
			sync_typeform("sync_typeform_gravity");		
		});

		function sync_typeform(callback){
			$.ajax({
				type: "post",
				dataType: "json",
				url: tfAjax.ajaxurl,
				data: {
					action: callback,
				},
				success: function(response) {
					$("#sync_loading_spinner").hide();							
					if(response.success) {
						const data = Object.values(response.data);
						if(data.length == 0){
							$('#tf-notice').append( 
								'<div class="notice notice-warning is-dismissible"><p>No new entry found!</p></div>' 
							);
						} else if (data.length > 0 ){
							let msg = '<div class="notice notice-warning is-dismissible">';
							data.forEach((item)=> {								
								msg += '<p>'+ item +'</p>';
							});
							msg += '</div>';
							$('#tf-notice').append(msg);							
						}												         
		            }
				},
				error: function(response){
					$("#sync_loading_spinner").hide();					
				}
			});
		}
	});
} )( jQuery );