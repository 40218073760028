/*SFH Page*/
$(document).ready(function(){
    //to check if '.navbar-right #sustainable-future' class name is not exist in this page
    if ($('.navbar-right #sustainable-future').length <= 0) {
        $('.navbar-right a:contains(Sustainable Future) .caret').css('display', 'none');
    }
});

//to check if '.page-template-sfh-landing-page' class name exist in this page
if ($('.page-template-sfh-landing-page').length > 0) {
    /*$(document).ready(function(){

       $.urlParam = function(name){
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
            return results[1] || 0;
        }

        // example.com?param1=name&param2=&id=6
        $.urlParam('sfh-nav-tab'); // name*/

        
        /*nav_tab = $.urlParam('sfh-nav-tab');
        //example params w/o space
        nav_tab_nospace = (unescape(nav_tab));

        $(".sfh-sustainability-nav a:contains('"+ nav_tab_nospace +"')").trigger('click');
        //$(".sfh-strategy__innercontainer__buttons a:contains('"+ nav_tab_nospace +"')").trigger('click');

    });*/


    /* Foootprint 2025 to make sure that only 1 item is collapse at one time */
    $(document).ready(function(){
        /* Open 1 item only */
        var selector = $('.card button[data-toggle="collapse"]');
        selector.on('click',function() {
          var self = this;
          if ($(this).hasClass('collapsed')) {
            $.each(selector, function(key, value) {
              if (!$(value).hasClass('collapsed') && value != self) {
                $(value).trigger('click');
              }
            });
          }
        });
        /* End open 1 item only */
    });

        /*Start - SFH Landing page - Section 2  FOOTPRINT*/
        
    /* Foootprint 2025 interactive section */
    /*Desktop Coords*/
    $("#coord-nsw").hover(function(){
        $("#sfh-footprint-map__nsw").addClass("tooltip-showed");
    });
    $("#coord-nsw").mouseleave(function(){
        $("#sfh-footprint-map__nsw").removeClass("tooltip-showed");
    });
    $("#coord-vic").hover(function(){
        $("#sfh-footprint-map__vic").addClass("tooltip-showed");
    });
    $("#coord-vic").mouseleave(function(){
        $("#sfh-footprint-map__vic").removeClass("tooltip-showed");
    });
    $("#coord-wa").hover(function(){
        $("#sfh-footprint-map__wa").addClass("tooltip-showed");
    });
    $("#coord-wa").mouseleave(function(){
        $("#sfh-footprint-map__wa").removeClass("tooltip-showed");
    });
    $("#coord-qld").hover(function(){
        $("#sfh-footprint-map__qld").addClass("tooltip-showed");
    });
    $("#coord-qld").mouseleave(function(){
        $("#sfh-footprint-map__qld").removeClass("tooltip-showed");
    });
    $("#coord-tas").hover(function(){
        $("#sfh-footprint-map__tas").addClass("tooltip-showed");
    });
    $("#coord-tas").mouseleave(function(){
        $("#sfh-footprint-map__tas").removeClass("tooltip-showed");
    });

    $("#coord-nsw").click(function(){
        $("#sfh-footprint-map__nsw").css("display", "block");
        $("#sfh-footprint-map__qld").css("display", "none");
        $("#sfh-footprint-map__wa").css("display", "none");
        $("#sfh-footprint-map__vic").css("display", "none");
        $("#sfh-footprint-map__tas").css("display", "none");

        $("#sfh-footprint-accordion__nsw").css("display", "block");
        $("#sfh-footprint-accordion__qld").css("display", "none");
        $("#sfh-footprint-accordion__wa").css("display", "none");
        $("#sfh-footprint-accordion__vic").css("display", "none");
        $("#sfh-footprint-accordion__tas").css("display", "none");
    });

    $("#coord-qld").click(function(){
        $("#sfh-footprint-map__nsw").css("display", "none");
        $("#sfh-footprint-map__qld").css("display", "block");
        $("#sfh-footprint-map__wa").css("display", "none");
        $("#sfh-footprint-map__vic").css("display", "none");
        $("#sfh-footprint-map__tas").css("display", "none");

        $("#sfh-footprint-accordion__nsw").css("display", "none");
        $("#sfh-footprint-accordion__qld").css("display", "block");
        $("#sfh-footprint-accordion__wa").css("display", "none");
        $("#sfh-footprint-accordion__vic").css("display", "none");
        $("#sfh-footprint-accordion__tas").css("display", "none");
    });

    $("#coord-wa").click(function(){
        $("#sfh-footprint-map__nsw").css("display", "none");
        $("#sfh-footprint-map__qld").css("display", "none");
        $("#sfh-footprint-map__wa").css("display", "block");
        $("#sfh-footprint-map__vic").css("display", "none");
        $("#sfh-footprint-map__tas").css("display", "none");

        $("#sfh-footprint-accordion__nsw").css("display", "none");
        $("#sfh-footprint-accordion__qld").css("display", "none");
        $("#sfh-footprint-accordion__wa").css("display", "block");
        $("#sfh-footprint-accordion__vic").css("display", "none");
        $("#sfh-footprint-accordion__tas").css("display", "none");
    });

    $("#coord-vic").click(function(){
        $("#sfh-footprint-map__nsw").css("display", "none");
        $("#sfh-footprint-map__qld").css("display", "none");
        $("#sfh-footprint-map__wa").css("display", "none");
        $("#sfh-footprint-map__vic").css("display", "block");
        $("#sfh-footprint-map__tas").css("display", "none");

        $("#sfh-footprint-accordion__nsw").css("display", "none");
        $("#sfh-footprint-accordion__qld").css("display", "none");
        $("#sfh-footprint-accordion__wa").css("display", "none");
        $("#sfh-footprint-accordion__vic").css("display", "block");
        $("#sfh-footprint-accordion__tas").css("display", "none");
    });
    
    $("#coord-tas").click(function(){
        $("#sfh-footprint-map__nsw").css("display", "none");
        $("#sfh-footprint-map__qld").css("display", "none");
        $("#sfh-footprint-map__wa").css("display", "none");
        $("#sfh-footprint-map__vic").css("display", "none");
        $("#sfh-footprint-map__tas").css("display", "block");

        $("#sfh-footprint-accordion__nsw").css("display", "none");
        $("#sfh-footprint-accordion__qld").css("display", "none");
        $("#sfh-footprint-accordion__wa").css("display", "none");
        $("#sfh-footprint-accordion__vic").css("display", "none");
        $("#sfh-footprint-accordion__tas").css("display", "block");
    });
    /* /Desktop Coords*/

    /* Mobile Coords*/
    $("#coord-nsw-mobile").hover(function(){
        $("#sfh-footprint-map-mobile__nsw").css("display", "block");
        $("#sfh-footprint-map-mobile__qld").css("display", "none");
        $("#sfh-footprint-map-mobile__wa").css("display", "none");
        $("#sfh-footprint-map-mobile__vic").css("display", "none");
        $("#sfh-footprint-map-mobile__tas").css("display", "none");
    });

    $("#coord-qld-mobile").hover(function(){
        $("#sfh-footprint-map-mobile__nsw").css("display", "none");
        $("#sfh-footprint-map-mobile__qld").css("display", "block");
        $("#sfh-footprint-map-mobile__wa").css("display", "none");
        $("#sfh-footprint-map-mobile__vic").css("display", "none");
        $("#sfh-footprint-map-mobile__tas").css("display", "none");
    });

    $("#coord-wa-mobile").hover(function(){
        $("#sfh-footprint-map-mobile__nsw").css("display", "none");
        $("#sfh-footprint-map-mobile__qld").css("display", "none");
        $("#sfh-footprint-map-mobile__wa").css("display", "block");
        $("#sfh-footprint-map-mobile__vic").css("display", "none");
        $("#sfh-footprint-map-mobile__tas").css("display", "none");
    });

    $("#coord-vic-mobile").hover(function(){
        $("#sfh-footprint-map-mobile__nsw").css("display", "none");
        $("#sfh-footprint-map-mobile__qld").css("display", "none");
        $("#sfh-footprint-map-mobile__wa").css("display", "none");
        $("#sfh-footprint-map-mobile__vic").css("display", "block");
        $("#sfh-footprint-map-mobile__tas").css("display", "none");
    });
    
    $("#coord-tas-mobile").hover(function(){
        $("#sfh-footprint-map-mobile__nsw").css("display", "none");
        $("#sfh-footprint-map-mobile__qld").css("display", "none");
        $("#sfh-footprint-map-mobile__wa").css("display", "none");
        $("#sfh-footprint-map-mobile__vic").css("display", "none");
        $("#sfh-footprint-map-mobile__tas").css("display", "block");
    });

    $("#coord-nsw-mobile").click(function(){
        $("#sfh-footprint-map-mobile__nsw").css("display", "block");
        $("#sfh-footprint-map-mobile__qld").css("display", "none");
        $("#sfh-footprint-map-mobile__wa").css("display", "none");
        $("#sfh-footprint-map-mobile__vic").css("display", "none");
        $("#sfh-footprint-map-mobile-mobile__tas").css("display", "none");

        $("#sfh-footprint-accordion__nsw").css("display", "block");
        $("#sfh-footprint-accordion__qld").css("display", "none");
        $("#sfh-footprint-accordion__wa").css("display", "none");
        $("#sfh-footprint-accordion__vic").css("display", "none");
        $("#sfh-footprint-accordion__tas").css("display", "none");
    });

    $("#coord-qld-mobile").click(function(){
        $("#sfh-footprint-map-mobile__nsw").css("display", "none");
        $("#sfh-footprint-map-mobile__qld").css("display", "block");
        $("#sfh-footprint-map-mobile__wa").css("display", "none");
        $("#sfh-footprint-map-mobile__vic").css("display", "none");
        $("#sfh-footprint-map-mobile-mobile__tas").css("display", "none");

        $("#sfh-footprint-accordion__nsw").css("display", "none");
        $("#sfh-footprint-accordion__qld").css("display", "block");
        $("#sfh-footprint-accordion__wa").css("display", "none");
        $("#sfh-footprint-accordion__vic").css("display", "none");
        $("#sfh-footprint-accordion__tas").css("display", "none");
    });

    $("#coord-wa-mobile").click(function(){
        $("#sfh-footprint-map-mobile__nsw").css("display", "none");
        $("#sfh-footprint-map-mobile__qld").css("display", "none");
        $("#sfh-footprint-map-mobile__wa").css("display", "block");
        $("#sfh-footprint-map-mobile__vic").css("display", "none");
        $("#sfh-footprint-map-mobile-mobile__tas").css("display", "none");

        $("#sfh-footprint-accordion__nsw").css("display", "none");
        $("#sfh-footprint-accordion__qld").css("display", "none");
        $("#sfh-footprint-accordion__wa").css("display", "block");
        $("#sfh-footprint-accordion__vic").css("display", "none");
        $("#sfh-footprint-accordion__tas").css("display", "none");
    });

    $("#coord-vic-mobile").click(function(){
        $("#sfh-footprint-map-mobile-mobile__nsw").css("display", "none");
        $("#sfh-footprint-map-mobile-mobile__qld").css("display", "none");
        $("#sfh-footprint-map-mobile-mobile__wa").css("display", "none");
        $("#sfh-footprint-map-mobile-mobile__vic").css("display", "block");
        $("#sfh-footprint-map-mobile-mobile__tas").css("display", "none");

        $("#sfh-footprint-accordion__nsw").css("display", "none");
        $("#sfh-footprint-accordion__qld").css("display", "none");
        $("#sfh-footprint-accordion__wa").css("display", "none");
        $("#sfh-footprint-accordion__vic").css("display", "block");
        $("#sfh-footprint-accordion__tas").css("display", "none");
    });
    
    $("#coord-tas-mobile").click(function(){
        $("#sfh-footprint-map-mobile-mobile__nsw").css("display", "none");
        $("#sfh-footprint-map-mobile-mobile__qld").css("display", "none");
        $("#sfh-footprint-map-mobile-mobile__wa").css("display", "none");
        $("#sfh-footprint-map-mobile-mobile__vic").css("display", "none");
        $("#sfh-footprint-map-mobile-mobile__tas").css("display", "block");

        $("#sfh-footprint-accordion__nsw").css("display", "none");
        $("#sfh-footprint-accordion__qld").css("display", "none");
        $("#sfh-footprint-accordion__wa").css("display", "none");
        $("#sfh-footprint-accordion__vic").css("display", "none");
        $("#sfh-footprint-accordion__tas").css("display", "block");
    });
    /* /Mobile Coords*/

        /*End - SFH Landing page - Section 2  FOOTPRINT*/
    /*interactive banner on 768px screen size and above*/
    if (window.matchMedia('(min-width: 768px)').matches) {
        /*$(window).scroll(function(e){
            parallaxScroll();
        });

        function parallaxScroll(){
            var scrolled = $(window).scrollTop();
            $('.hero-layers__strategy').css('top',(50-(scrolled*.25))+'px');
            $('.hero-layers__footprint').css('top',(175-(scrolled*.50))+'px');
            $('.hero-layers__sustainability').css('top',(330-(scrolled*.75))+'px');
        }*/

        setTimeout(function() {
             slider_onload();
        }, 2000);

        function slider_onload(){
            $(".hero-layers__footprint").fadeIn()
            .css({top:0,position:'absolute'})
            .animate({top:"0vh"}, 800, function() {
                $(".layer-text").css("opacity", "0").animate({opacity:1});
            });

            $(".hero-layers__strategy").fadeIn()
            .css({top:0,position:'absolute'})
            .animate({top:"15vh"}, 800, function() {
                $(".layer-text").css("opacity", "0").animate({opacity:1});
            });

            $(".hero-layers__sustainability").fadeIn()
            .css({top:0,position:'absolute'})
            .animate({top:"28vh"}, 800, function() {                
            });


        }
    };

    /*$(window).resize(function() {
        if (window.matchMedia('(min-width: 769px)').matches) {
            $(window).scroll(function(e){
            parallaxScroll();
        });

        function parallaxScroll(){
            var scrolled = $(window).scrollTop();
            $('.hero-layers__strategy').css('top',(50-(scrolled*.25))+'px');
            $('.hero-layers__footprint').css('top',(175-(scrolled*.50))+'px');
            $('.hero-layers__sustainability').css('top',(330-(scrolled*.75))+'px');
        }

        setTimeout(function() {
             slider_onload();
        }, 2000);

        function slider_onload(){
            $(".hero-layers__footprint").fadeIn()
            .css({top:50,position:'absolute'})
            .animate({top:175}, 800, function() {
                $(".layer-text").css("opacity", "0").animate({opacity:1});
            });
            $(".hero-layers__sustainability").fadeIn()
            .css({top:50,position:'absolute'})
            .animate({top:330}, 800, function() {

            });
        }
        }
    });*/

​$(".inner-link, #hero-layer a, #fp-nav a, .list-content a").on('click', function(event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();
      // Store hash
      var hash = this.hash;
      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 200, function(){
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
});


$(".mobilemenu__button").click(function(){
    $('.mobilemenu__panel').toggle('slide');           
})
$(".mobile-close").click(function(){
    $('.mobilemenu__panel').toggle('slide');          
})

/*
Strategy section
5 buttons foundation. People, Market, Assets, Finances, Earch
Function: Showing or Hiding content when User clicks on Button
*/
$(".tabs-link").click(function(e){

    e.stopPropagation();

    $(".tabs-link").removeClass("selected"); 

    $("#sfh-strategy .tab-content").addClass("hidden"); 

    $(this).addClass("selected");

    var get_tab = "#" + $(this).attr("data-tab");

    $(get_tab).removeClass("hidden");

    //menu

    var get_data_tab = $(this).attr("data-tab");

    /*$(".mainlist").removeClass("active");*/
    $('.sfh_strategy_menu_link').next().addClass("hidden");
    $(".list-info-main").addClass("hidden");

    var menu_by_data = $('.sfh_strategy_menu_link[data-stg="'+get_data_tab+'"]');  

    menu_by_data.next().removeClass("hidden");

}); 

/*
Narvigation Bar
4 Points and sub-points
Function: Move to section which user clicked 
*/
$(".mainlist").click(function(e){

    e.stopPropagation();

    $(".mainlist").removeClass("active");
    $(this).addClass("active");

    if($(this).find('.activecircle').length < 1){
        $(".activecircle").remove();
        var list_active = '<span class="activecircle"></span>';
        $(this).append(list_active);
        $(".list-info-main").addClass("hidden");
        $(".sub-list").addClass("hidden");
        $(this).find(".list-info-main").removeClass("hidden");
    }
    
});

$(".mainlist").hover(function(e){

    e.stopPropagation();

    if ($(e.target).is(".bigbullet") || $(e.target).is(".activecircle")) {

        $(".list-info-main").addClass("hidden");
        $(".sub-list").addClass("hidden");
        $(this).find(".list-info-main").removeClass("hidden");
    }
    
});



/*
Narvigation Bar - Sub menu of Strategy section
5 Sub point
Function: Showing and Hiding the strategy content when user clicks, hover on sub-menu
*/
$(".sfh_strategy_menu_link").on('click', function (e) {

    e.stopPropagation();

    $(".tabs-link").removeClass("selected");

    $("#sfh-strategy .tab-content").addClass("hidden"); 

    $(".list-info-main").addClass("hidden");

    $(".sub-list").addClass("hidden");

var get_sub_mene = $('span[data-stg="'+$(this).attr("data-stg")+'"]');

$(get_sub_mene).next().removeClass("hidden");

    var get_stg_tag = $(this).attr("data-stg");

    var get_stg_tag_id = "#" + $(this).attr("data-stg");

    var get_tab_by_data = $('.tabs-link[data-tab="'+get_stg_tag+'"]');

    $(get_tab_by_data).addClass('selected');

    $(get_stg_tag_id).removeClass("hidden");

}); 


/*
Narvigation Bar - Sub menu of Sustainability for you
6 Sub points
Function: Showing and Hiding the strategy content when user clicks, hover on sub-menu
*/
$(".sfh_Sustainability_menu_link").on('click', function (e) {

    e.stopPropagation();

    $(".tabs-link").removeClass("selected");

    $(".list-info-main").addClass("hidden");

    $(".sub-list").addClass("hidden");

    $(this).next().removeClass("hidden");

    var get_index_link = $( ".sfh_Sustainability_menu_link" ).index(this);

    var get_sustainability_tab = "#sfycontent" + get_index_link;

    $('.sustainable-future__tabs li').removeClass("active");

    $('a.content'+get_index_link).parent().addClass("active");
    
    $('.sfh-sustainability .tab-content .tab-pane').removeClass("in active");

    $(get_sustainability_tab).addClass("in active");


}); 

/*
Narvigation Bar - Sub menu of Strategy section
5 Sub point
Function: Showing and Hiding the strategy content when user hover on sub-menu
*/
$(".sublist .sfh_strategy_menu_link").hover(function(e){

    e.stopPropagation();

    $(".tabs-link").removeClass("selected");

    $("#sfh-strategy .tab-content").addClass("hidden"); 

    $(".list-info-main").addClass("hidden");

    $(".sub-list").addClass("hidden");

    var get_sub_mene = $('span[data-stg="'+$(this).attr("data-stg")+'"]');

    $(get_sub_mene).next().removeClass("hidden");

    var get_stg_tag = $(this).attr("data-stg");

    var get_stg_tag_id = "#" + $(this).attr("data-stg");

    var get_tab_by_data = $('.tabs-link[data-tab="'+get_stg_tag+'"]');

    $(get_tab_by_data).addClass('selected');

    $(get_stg_tag_id).removeClass("hidden");

}); 




/*
Narvigation Bar - Sub menu of Sustainability for you
6 Sub points
Function: Showing and Hiding the strategy content when user clicks on sub-menu
*/
$(".sublist .sfh_Sustainability_menu_link").hover(function(e){

    e.stopPropagation();

    $(".tabs-link").removeClass("selected");

    $(".list-info-main").addClass("hidden");

    $(".sub-list").addClass("hidden");

    $(this).next().removeClass("hidden");

    var get_index_link = $( ".sfh_Sustainability_menu_link" ).index(this);

    var get_sustainability_tab = "#sfycontent" + get_index_link;

    $('.sustainable-future__tabs li').removeClass("active");

    $('a.content'+get_index_link).parent().addClass("active");
    
    $('.sfh-sustainability .tab-content .tab-pane').removeClass("in active");

    $(get_sustainability_tab).addClass("in active");

}); 

/*
Narvigation Bar - Sub menu of Sustainability for you
6 Sub points
Function: Showing and Hiding the strategy content when user clicks on sub-menu
*/
$(".sfh_Sustainability_menu_link_hover").click(function(e){

    e.stopPropagation();

    $(".tabs-link").removeClass("selected");

    $(".list-info-main").addClass("hidden");

    $(".sub-list").addClass("hidden");

    var get_sub_mene = $('.sfh_Sustainability_menu_link').eq($(".sfh_Sustainability_menu_link_hover" ).index(this));

    $(get_sub_mene).next().removeClass("hidden");

    var get_index_link = $( ".sfh_Sustainability_menu_link_hover" ).index(this);

    var get_sustainability_tab = "#sfycontent" + get_index_link;

    $('.sustainable-future__tabs li').removeClass("active");

    $('a.content'+get_index_link).parent().addClass("active");
    
    $('.sfh-sustainability .tab-content .tab-pane').removeClass("in active");

    $(get_sustainability_tab).addClass("in active");


}); 


/*
Narvigation Bar - Actived menu when scroll up or down
Function: Displaying menu content 
*/
if($("#sfh-report").length === 0) {
    $("#report-dot").addClass("v-hidden");
}
    
$(window).scroll(function() {

    var top_of_sfh_banner = $("#sfh").offset().top;
    var bottom_of_sfh_banner = $("#sfh").offset().top + $("#sfh").outerHeight();

    var top_of_sfh_strategy = $("#sfh-strategy").offset().top;
    var bottom_of_sfh_strategy = $("#sfh-strategy").offset().top + $("#sfh-strategy").outerHeight();
    
    if($("#sfh-report").length !== 0) {
        var top_of_sfh_report = $("#sfh-report").offset().top;
        var bottom_of_sfh_report = $("#sfh-report").offset().top + $("#sfh-report").outerHeight();
    }
    var top_of_sfh_footprint = $("#sfh-footprint").offset().top;
    var bottom_of_sfh_footprint = $("#sfh-footprint").offset().top + $("#sfh-footprint").outerHeight();

    var top_of_sfh_sustainability = $("#sfh-sustainability").offset().top;
    var bottom_of_sfh_sustainability = $("#sfh-sustainability").offset().top + $("#sfh-sustainability").outerHeight();

    var top_of_footer = $("#colophon").offset().top;
    var bottom_of_footer = $("#colophon").offset().top + $("#colophon").outerHeight();

    var top_of_sfh_newscroom = $("#sfh-newsroom").offset().top - 300;
    var bottom_of_sfh_newscroom = $("#sfh-newsroom").offset().top + $("#sfh-newsroom").outerHeight();

    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight() - 300;
    var top_of_screen = $(window).scrollTop() + $(window).innerHeight() - 300;

    var sfh_banner_menu = $(".mainlist").eq(0);
    var sfh_sustainability_menu = $(".mainlist").eq(1);
    var sfh_strategy_menu = $(".mainlist").eq(2);
    var sfh_report_menu = $(".mainlist").eq(3);
    var sfh_footprint_menu = $(".mainlist").eq(4);
    var sfh_newscroom_menu = $(".mainlist").eq(5);

    if ((bottom_of_screen > top_of_footer) && (top_of_screen < bottom_of_footer)){
        $("#fp-nav").addClass("hidden");
    }else{
        $("#fp-nav").removeClass("hidden");
    }

    if ((bottom_of_screen > top_of_sfh_banner) && (top_of_screen < bottom_of_sfh_banner)){
        //$("#strategy-dot").css("margin", "130px 0px 130px 7.5px");
        $("#strategy-dot").removeAttr("style");
        $("#report-dot").removeAttr("style");
        sfh_banner_menu.addClass("active");
        $("#strategy-dot").removeClass("active-list");
        $("#sustanable-dot").removeClass("active-list");
        if($(sfh_banner_menu).find('.activecircle').length < 1){
            $(".activecircle").remove();
            var list_active = '<span class="activecircle"></span>';
            $(sfh_banner_menu).append(list_active);
            $(".list-info-main").addClass("hidden");
            $(".sub-list").addClass("hidden");
            $(".sub-list").removeClass("hidden");
            $("#strategy-dot").removeClass("active-list");
            $("#sustanable-dot").removeClass("active-list");
            $("#strategy-dot .tri-right").css("display", "block");
        }
    } else{
        $(sfh_banner_menu).removeClass("active");
        $(sfh_banner_menu).find('.activecircle').remove();
        $(sfh_banner_menu).find(".list-info-main").addClass("hidden");
    }
    
    /*Sustainability Section*/
    if((bottom_of_screen > top_of_sfh_sustainability) && (top_of_screen < bottom_of_sfh_sustainability)){
        $("#strategy-dot").css("margin", "210px 0px 30px 7.5px");
        $("#report-dot").css("margin", "0 8px 55px");
        $("#sustanable-dot .sublist").css("display", "block");
        $("#strategy-dot .sublist").css("display", "none");
        $(".nav-tabs a").click(function(){
            $("#sustanable-dot").addClass("active-list");
            $("#strategy-dot").removeClass("active-list");
            $(".tri-right").css("display", "block");
        });
        sfh_sustainability_menu.addClass("active");
        if($(sfh_sustainability_menu).find('.activecircle').length < 1){
            $(".activecircle").remove();
            var list_active = '<span class="activecircle"></span>';
            $(sfh_sustainability_menu).append(list_active);
            $(".list-info-main").addClass("hidden");
            $(".sub-list").addClass("hidden");
            $("#sustanable-dot").addClass("active-list");
            $("#strategy-dot").removeClass("active-list");
            $(".tri-right").css("display", "block");
        }
    }else{
        $(sfh_sustainability_menu).removeClass("active");
        $(sfh_sustainability_menu).find('.activecircle').remove();
        $(sfh_sustainability_menu).find(".list-info-main").addClass("hidden");
    }

    /*Strategy Section*/
    if((bottom_of_screen > top_of_sfh_strategy) && (top_of_screen < bottom_of_sfh_strategy)){
        $("#strategy-dot").css("margin", "-45px 0 190px 7.5px");
        $("#report-dot").css("margin", "0 8px 55px");
        $("#sustanable-dot .sublist").css("display", "none");
        $("#strategy-dot .sublist").css("display", "block");
        $(".sfh-strategy__innercontainer__buttons a").click(function(){
            $("#strategy-dot").addClass("active-list");
            $("#sustanable-dot").removeClass("active-list");
            $(".tri-right").css("display", "block");
        });
        sfh_strategy_menu.addClass("active");
        $("#strategy-dot").addClass("active-list");
        $("#sustanable-dot").removeClass("active-list");
        if($(sfh_strategy_menu).find('.activecircle').length < 1){
            $(".activecircle").remove();
            var list_active = '<span class="activecircle"></span>';
            $(sfh_strategy_menu).append(list_active);
            $(".list-info-main").addClass("hidden");
            $(".sub-list").addClass("hidden");
            $("#strategy-dot").addClass("active-list");
            $("#sustanable-dot").removeClass("active-list");
            $(".tri-right").css("display", "block");
        }
    } else {
        $(sfh_strategy_menu).removeClass("active");
        $(sfh_strategy_menu).find('.activecircle').remove();
        $(sfh_strategy_menu).find(".list-info-main").addClass("hidden");
    }
    
    /*Report Section*/
    if((bottom_of_screen > top_of_sfh_report) && (top_of_screen < bottom_of_sfh_report)){
       
        //sfh_strategy_menu.addClass("active");
        $("#strategy-dot").removeAttr("style");
        $("#report-dot").removeAttr("style");
        $(sfh_strategy_menu).find('.activecircle').remove();
        $(sfh_footprint_menu).find('.activecircle').remove();

        $("#sustanable-dot .sublist").css("display", "none");
        $("#strategy-dot .sublist").css("display", "none");
        sfh_report_menu.addClass("active");
        if($(sfh_report_menu).find('.activecircle').length < 1){
            var list_active = '<span class="activecircle"></span>';
            $(sfh_report_menu).append(list_active);
            $("#strategy-dot").removeClass("active-list");
            $("#sustanable-dot").removeClass("active-list");
            //$("#sfh-newscroom-dot").removeClass("active-list");

        }
    }else {
        $(sfh_footprint_menu).removeClass("active");
        $(sfh_footprint_menu).find('.activecircle').remove();
        $(sfh_footprint_menu).find(".list-info-main").addClass("hidden");
    }

    /*Footprint Section*/
    if((bottom_of_screen > top_of_sfh_footprint) && (top_of_screen < bottom_of_sfh_footprint)){
       
        sfh_strategy_menu.addClass("active");
        
        $(sfh_report_menu).find('.activecircle').remove();
        $(sfh_newscroom_menu).find('.activecircle').remove();

        $("#sustanable-dot .sublist").css("display", "none");
        $("#strategy-dot .sublist").css("display", "none");
        sfh_footprint_menu.addClass("active");
        if($(sfh_footprint_menu).find('.activecircle').length < 1){
            var list_active = '<span class="activecircle"></span>';
            $(sfh_footprint_menu).append(list_active);
            $("#strategy-dot").removeClass("active-list");
            $("#sustanable-dot").removeClass("active-list");
            //$("#sfh-newscroom-dot").removeClass("active-list");

        }
    }else {
        $(sfh_footprint_menu).removeClass("active");
        $(sfh_footprint_menu).find('.activecircle').remove();
        $(sfh_footprint_menu).find(".list-info-main").addClass("hidden");
    }

    /*newscroom Section*/
    if((bottom_of_screen > top_of_sfh_newscroom) && (top_of_screen < bottom_of_sfh_newscroom)){
        
        $(sfh_footprint_menu).find('.activecircle').remove();
    
        $("#sustanable-dot .sublist").css("display", "none");
        $("#strategy-dot .sublist").css("display", "none");
        sfh_newscroom_menu.addClass("active");
        if($(sfh_newscroom_menu).find('.activecircle').length < 1){
            var list_active = '<span class="activecircle"></span>';
            $(sfh_newscroom_menu).append(list_active);
            $("#strategy-dot").removeClass("active-list");
            $("#sustanable-dot").removeClass("active-list");
            $("#footprint-dot").removeClass("active-list");
            $("#report-dot").removeClass("active-list");
        }
    }else {
        $(sfh_newscroom_menu).removeClass("active");
        $(sfh_newscroom_menu).find('.activecircle').remove();
        $(sfh_newscroom_menu).find(".list-info-main").addClass("hidden");
    }


});

/*
Subtainaility - tabs 
Function: active menu when user click on Sus's tabs
*/
$(".sfh-sustainability-nav li").click(function(e){

    $(".mainlist").removeClass("active");
    $('.sfh_Sustainability_menu_link').next().addClass("hidden");
    $(".list-info-main").addClass("hidden");

    var get_index_li = $( ".sfh-sustainability-nav li" ).index(this);

    var menu_by_data = $('.sfh_Sustainability_menu_link').eq(get_index_li);  

    menu_by_data.next().removeClass("hidden");

}); 

/*$(".dropdown, .btn-group").hover(function(){
    var dropdownMenu = $(this).children(".dropdown-menu");
    if(dropdownMenu.is(":visible")){
        dropdownMenu.parent().toggleClass("open");
    }
});*/

}

/*
Clearing of the URL
$(document).ready(function(){
var url = window.location.href;
var hash = url.substring(url.indexOf('#'));
var page_url = url = url.slice( 0, url.indexOf('?') );
alert( page_url + hash );
});*/


/*SFH Page*/
if ($('.page-template-SFH-template').length > 0) {
    /* Animated banner */
    $(document).ready(function(){
        if (window.matchMedia('(min-width: 769px)').matches) {
           
            setTimeout(function() {
                 slider_onload();
            }, 2000);
             

            function slider_onload(){
                $(".hero-layers__footprint").fadeIn()
                .css({top:50,position:'absolute'})
                .animate({top:'15vh'}, 800, function() {
                    $(".layer-text").css("opacity", "0").animate({opacity:1});
                });
                $(".hero-layers__sustainability").fadeIn()
                .css({top:50,position:'absolute'})
                .animate({top:'30vh'}, 800, function() {
                    
                });
            }
        };

        ​$(".inner-link").on('click', function(event) {
            // Make sure this.hash has a value before overriding default behavior
            if (this.hash !== "") {
              // Prevent default anchor click behavior
              event.preventDefault();
              // Store hash
              var hash = this.hash;
              // Using jQuery's animate() method to add smooth page scroll
              // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
              $('html, body').animate({
                scrollTop: $(hash).offset().top
              }, 200, function(){
                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash;
              });
            } // End if
        });

/*        $(".tab").click(function() {
            if(!$(this).hasClass('active'))
            {
                $(".tab.active").removeClass("active");
                $(this).addClass("active");        
            } 
        });
        $("#tab1").click(function(){
                $('#content1').show();  
                $('#content2').hide();        
        })
        $("#tab2").click(function(){
                $('#content1').hide();  
                $('#content2').show();        
        })*/

        $(".mobilemenu__button").click(function(){
                $('.mobilemenu__panel').toggle('slide');  
                    
        })
        $(".mobile-close").click(function(){
                $('.mobilemenu__panel').toggle('slide');  
                    
        })
        $(".navlink").click(function() {
            if(!$(this).hasClass('active'))
            {
                $(this).addClass("active");      
            } 
        });


        
        
    });
}


//CSS SFH menu
//hover on all links under '#menu-sfh-submenu'
$( "#menu-sfh-submenu li a" ).hover(
  function() {
    $(this).parent().addClass("colorwhitehover");
  }, function() {
    $(this).parent().removeClass("colorwhitehover");
  }
);



if (jQuery(".crr_section").val()) {

    $(".tabs-link").removeClass("selected"); 

    var crr_section = jQuery(".crr_section").val();

    var get_tab_by_data = jQuery('.tabs-link[data-tab="'+crr_section+'"]');

    $(get_tab_by_data).addClass('selected');

    $("#sfh-strategy .tab-content").addClass("hidden"); 

    $("#" + crr_section).removeClass("hidden");

    

    var timer = null;
    window.addEventListener('scroll', function() {
        if(timer !== null) {
            clearTimeout(timer);        
        }
        timer = setTimeout(function() {
              $('.sfh_strategy_menu_link').next().addClass("hidden");

                $(".list-info-main").addClass("hidden");

                var menu_by_data = $('.sfh_strategy_menu_link[data-stg="'+crr_section+'"]');  

                menu_by_data.next().removeClass("hidden");
                
        }, 150);
    }, false);


}